import React, { useState, useEffect, useRef } from "react";
import Navbar from "./Navbar"
import Footer from "./Footer"
let hasRan = false;

function Card(props) {
    const assignmentTypes = props.assignmentTypes
    const paragraphRef = useRef(null)
    const typeMap = new Map()
    const colors = ["#22c55e","#3b82f6",
            "#eab308","#f97316","#ef4444"
        ]
    const lastColor = "#dc2626"

    console.log("assignment Types",assignmentTypes)
    for (let i=0; i<assignmentTypes.length; i++) {
        typeMap.set(assignmentTypes[i].assignmentType, assignmentTypes[i].categoryWorth)
    }

    


    useEffect(() => {

        return () => {
            setAllAssignments([])
            setTotalGrade(3)
            setPoints([])
        }

    }, [])


    const [allAssignments, setAllAssignments] = useState(props.assignments)
    const [totalGrade, setTotalGrade] = useState(props.course["grade"])
    const [assignmentAdded, setAssignmentAdded] = useState(false)
    const [whatIf, setWhatIf] = useState(
        {
            name: "Delulu Assignment",
            category: allAssignments[0].category,
            totalEarned: 0,
            totalPoints: 10,
            date: new Date().toLocaleDateString()
            


        }
    )
    const [points, setPoints] = useState([])


    const closeCard = () => {
        setAllAssignments([])
        setPoints([]) 

        props.setCurrentCard(undefined)
    }


    const toggleItem = (event) => {
        setAssignmentAdded(true)
        // console.log('sdf')
    }




    const findIndex = (value) => {
        console.log("points value",points)
        for (let i=0; i<points.length; i++) {
            if (points[i].assignmentType === value) {
                // console.log("i returned this i value", i)
                return i
            } else {
                // do nothing
            }
        }
        // console.log("nothing was sent :(")


      
    }

    const removeItem = (event) => {
        setAllAssignments(items => {
            let prevItems = [...items]
            prevItems.splice(event.target.id, 1)
            return prevItems

        })
    }

   



    // This would be activated whenever a new person is clicked
    useEffect(() => {
            
            setPoints([])
            console.log("heres all of allAssignments right before useEFfect", allAssignments)

            for (let i=0; i<allAssignments.length; i++) {
                setPoints(points => {
                    let index;
                    for (let k=0; k<points.length; k++) {

                        if ((allAssignments[i].category) == (points[k].assignmentType)) {
                            index = k
                            break;
    
                        }
                    }
                    if (index === undefined) {
                        
                        return [...points, {
                            assignmentType: allAssignments[i].category,
                            categoryWorth: allAssignments[i].categoryWorth,
                            totalPoints: parseFloat(allAssignments[i].totalPoints),
                            totalEarned: parseFloat(allAssignments[i].totalEarned)
                        }]
                    } else {
                        let entireList = points
                        let prevPoints = entireList[index]
                        prevPoints['totalPoints'] = parseFloat(prevPoints['totalPoints']) + parseFloat(allAssignments[i].totalPoints)
                        prevPoints['totalEarned'] = parseFloat(prevPoints['totalEarned']) + parseFloat(allAssignments[i].totalEarned)
                        // console.log(points)

                   
    
                        return entireList
                    }
                })
            }

    

        
        

        hasRan = true
    }, [allAssignments])


    useEffect(() => {
        if (points.length > 0) {
            setTotalGrade(() => {
                let finalGrade = 0
                console.log("points right before render", points)
                for (let i=0; i<points.length; i++) {
                    finalGrade = finalGrade +  parseFloat(((points[i].totalEarned / points[i].totalPoints) * (points[i].categoryWorth/100)) * 100)
                }
                
                return parseFloat(finalGrade).toFixed(2)
    
    
    
    
            }) 
        }
    },[points])



    function checkGrade(grade) {
        let gradeBands = props.gradeBand


        console.log("the grade inputted", grade)
        for (let i=0; i<4; i++) {
            if (parseFloat(grade) >=gradeBands[i].lowScore) {
                console.log("i returned",i)
                return i

            }
        }
        console.log("-1 buddy")
        return -1






    }








    

    






    return (


        <>
        <div className="w-full bg-primary  text-white relative sm:h-fit min-h-screen">
        <Navbar color="#102542" name={props.name} />
            <div className="mx-auto sm:w-5/6 w-full ">
            
                

                <div className="sm:w-4/6 w-full mx-auto p-4">
                

                <div className="sm:w-4/6 w-full  mx-auto px-8 py-4 bg-secondary rounded-lg">
                <p className="font-1 text-2xl font-semibold">{props.course["courseName"]}</p>
                <p className="font-1 text-lg ">{props.course["courseTeacher"]}</p>
                    
                    {(checkGrade(totalGrade) >= 0) && (
                        <div className="w-full px-2 py-1 my-3  border-2 rounded-lg relative select-none" style={{borderColor: colors[checkGrade(totalGrade)]}}>
                        <div className="absolute top-0 left-0  h-full z-0 max-w-[100%]" style={{ width: totalGrade + '%', backgroundColor: colors[checkGrade(totalGrade)] }}>
                        </div>
                        <p className="font-1 relative z-10 text-white font-semibold">Total - {totalGrade}%</p>
                    </div>
                    )}
                    {(checkGrade(totalGrade) < 0) && (
                        <div className="w-full px-2 py-1 my-3  border-2 rounded-lg relative select-none" style={{borderColor: lastColor}}>
                        <div className="absolute top-0 left-0  h-full z-0 max-w-[100%]" style={{ width: totalGrade + '%', backgroundColor: lastColor }}>
                        </div>
                        <p className="font-1 relative z-10 text-white font-semibold">Total - {totalGrade}%</p>
                    </div>
                    )}
                    
                      
                    

                    
                        {((assignmentTypes != undefined) && (points.length != 0)) && (
                            points.map((type, i) => (
                            <div key={i}>
                            {(checkGrade((type.totalEarned/type.totalPoints)*100) >= 0) && (
                                <div className="w-full px-2 py-1 my-3 border-2 rounded-lg relative" style={{borderColor: colors[checkGrade((type.totalEarned/type.totalPoints)*100)]}}>
                                <div className="relative z-20 flex flex-row justify-between select-none">
                                {/* {console.log("heres the thing right: ",type.assignmentType)} */}
                                {/* {console.log("points value right before rendering",points[findIndex(type.assignmentType)].totalEarned)} */}
                                
                                <p className="font-1 text-white inline text-left">{type.assignmentType} - {parseFloat(((points[findIndex(type.assignmentType)].totalEarned)/(points[findIndex(type.assignmentType)].totalPoints))*100).toFixed(2)}%</p>
                                <p className="font-1 text-white inline text-right text-sm font-light">{(points[findIndex(type.assignmentType)].totalEarned).toFixed(2)}/{(points[findIndex(type.assignmentType)].totalPoints).toFixed(0)}</p>
                                
                                </div>
                               
                                <div className={"absolute top-0 left-0  h-full max-w-[100%]"} style={{ width: parseFloat(((points[findIndex(type.assignmentType)].totalEarned)/(points[findIndex(type.assignmentType)].totalPoints))*100).toFixed(2) + '%', backgroundColor: colors[checkGrade((type.totalEarned/type.totalPoints)*100)] }}>
                                


                                </div>
                            </div>

                            )}

                            {(checkGrade((type.totalEarned/type.totalPoints)*100) < 0) && (
                                <div className="w-full px-2 py-1 my-3 border-2 rounded-lg relative" style={{borderColor: lastColor}}>
                                <div className="relative z-20 flex flex-row justify-between select-none">

                                
                                <p className="font-1 text-white inline text-left">{type.assignmentType} - {parseFloat(((points[findIndex(type.assignmentType)].totalEarned)/(points[findIndex(type.assignmentType)].totalPoints))*100).toFixed(2)}%</p>
                                <p className="font-1 text-white inline text-right text-sm font-light">{(points[findIndex(type.assignmentType)].totalEarned).toFixed(2)}/{(points[findIndex(type.assignmentType)].totalPoints).toFixed(0)}</p>
                                
                                </div>
                               
                                <div className={"absolute top-0 left-0  h-full max-w-[100%]"} style={{ width: parseFloat(((points[findIndex(type.assignmentType)].totalEarned)/(points[findIndex(type.assignmentType)].totalPoints))*100).toFixed(2) + '%', backgroundColor: colors[checkGrade(type.totalEarned/type.totalPoints)], backgroundColor: lastColor }}>
                                


                                </div>
                            </div>
                            )}
                                
                            
                            
                            </div>
                        ))
                        )}
                        
                        
                    

                </div>


                </div>
                

                


            </div>
            <div className="w-full bg-secondary text-black py-4">

            
            <div className="sm:w-5/6 w-full mx-auto ">
            <div className="sm:w-4/6 w-full mx-auto rounded-lg bg-primary p-3">
                    <div className="w-full relative rounded-lg bg-secondary">
                        <p className="sm:p-4 p-2 font-1 text-white font-bold text-2xl ">Assignments:</p>
                        <div className="absolute right-2 top-[50%] translate-y-[-50%]">
                            {/* Plus icon */}
                            <div onClick={toggleItem} className="hover:bg-black  select-none cursor-pointer hover:text-white px-2 py-2 bg-white  rounded-full group transition-all relative inline-block mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
  <path fillRule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
</svg>

                            </div>
                            <div onClick={closeCard} className="hover:pr-6 select-none cursor-pointer hover:bg-black px-4 py-2 bg-white  rounded-full group transition-all relative inline-block">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 group-hover:translate-x-4 transition-all text-blue-500">
  <path fill-rule="evenodd" d="M16.72 7.72a.75.75 0 0 1 1.06 0l3.75 3.75a.75.75 0 0 1 0 1.06l-3.75 3.75a.75.75 0 1 1-1.06-1.06l2.47-2.47H3a.75.75 0 0 1 0-1.5h16.19l-2.47-2.47a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
</svg>
                            </div>
                        
                        
                        

                        </div>
                        
                    </div>
                    {(allAssignments.length === 0) && (
                        <div className="sm:p-5 p-3 rounded-lg flex flex-row items-center bg-white">
                            <p className="font-1 text-center">Nothing to see here</p>
                        

                        </div>
                    )}
                    {(allAssignments.length > 0) && (
                        
                        <div className="flex flex-col sm:p-4 p-2 gap-y-2 w-full relative max-h-[70vh] overflow-y-auto">

                        {(assignmentAdded) && (
                            <div className="sm:p-5 p-3 rounded-lg flex flex-row items-center bg-secondary text-white">
                            <div className="text-center sm:pr-4 pr-2 border-r-2">
                                <p className="font-1 text-lg text-red- font-bold text-red-500">{parseFloat((whatIf.totalEarned/whatIf.totalPoints) * 100).toFixed(2)}%</p>
                                <p className="font-1 text-sm font-semibold text-red-500">
                                <input className="text w-8 inline-block bg-white text-center rounded-full" value={whatIf.totalEarned} onChange={(e) => {
                                // The issue with this is that you cannot be decimals in this system or else the entire thing crashes.
                                
                                
                                
                                setWhatIf((ifWhat) => {
                                    let whatIf = {...ifWhat}
                                    if (e.target.value === "") {
                                        whatIf["totalEarned"] = parseFloat(0)
                                        return whatIf
                                    }

                                    if (isNaN(e.target.value)) {
                                        whatIf["totalEarned"] = e.target.value
                                    } else {
                                        parseFloat(whatIf["totalEarned"] = e.target.value)
                                    }
                                    return whatIf
                                })
                                
                                
                                // if (true) {
                                //     if ((Number(e.target.value) < Number(whatIf.totalPoints+1)) || (e.target.value === ".")) {
                                //         setWhatIf(ifWhat => {
                                //         let whatIf = {...ifWhat}
                                //         if (e.target.value == "") {
                                //             whatIf['totalEarned'] = 0
                                //         } else {
                                //             whatIf['totalEarned'] = parseInt(e.target.value)
                                //         }
                                //         console.log(whatIf)
                                //         return whatIf
                                //     })
                                //     } else {
                                //         console.log("this is getting happening")
                                //         setWhatIf(prevWhatIf => {
                                //             let w = prevWhatIf
                                //             console.log(w)
                                //             w["totalEarned"] = w["totalPoints"]
                                //             return w
                                //         })
                                //     }
                                    
                                // } else {
                                //     console.log("heres the thing right")
                                // }
                                }}
                             />
                            
                            
                            
                            /
                            
                            <input className="text w-8 inline-block bg-white text-center rounded-full" value={whatIf.totalPoints} onChange={(e) => {
                                // The issue with this is that you cannot be decimals in this system or else the entire thing crashes.
                             
                                    
                                    setWhatIf((ifWhat) => {
                                    let whatIf = {...ifWhat}

                                    if (e.target.value === "") {
                                        whatIf["totalPoints"] = parseFloat(0)
                                        return whatIf
                                    }
                                    if (isNaN(e.target.value)) {
                                        whatIf["totalPoints"] = e.target.value
                                    } else {
                                        parseFloat(whatIf["totalPoints"] = e.target.value)
                                    }



                                    return whatIf
                                })
                                 
                                    
                                    
                            
                                }}
                             />
                            
                            </p>

                            </div>
                            <div className="sm:pl-4 pl-2">
                            <input  className=" text-white bg-secondary  sm:w-fit w-5 font-2 text-base font-extrabold" value={whatIf.name} onInput={(e) => {setWhatIf(thing => {
                                let prevComb = {...thing}
                                

                                prevComb.name = e.target.value
                                
                                return prevComb
                            })
                            
                            
                            
                            }}></input>
                            <p className="text-sm font-2 font-light">


                            <select value={whatIf.category} className="p-1 font-2 font-light text-sm  bg-primary " onChange={(e) => {
                                setWhatIf(prevWhatIf => {
                                    
                                    let localIf = {...prevWhatIf}
                                    localIf.category = e.target.value
                                    return localIf
                                })
                            }}>
                                {points.map((type, i) => (
                                    <option value={type.assignmentType} key={i}>{type.assignmentType}</option>
                                ))}
                            </select>
                            
                            
                            
                            </p>
                            <div className="w-fit absolute top-1 right-3 rounded-full bg-primary select-none cursor-pointer">
                            <p className="font-1 text-sm px-3 py-1 bg-blue  text-white rounded-full text-center" onClick={(e) => {
                                setAllAssignments(prevAssignments => {
                                    let assignments = [...prevAssignments]
                                    assignments.unshift(
                                        {
                                            name: whatIf.name,
                                            category: whatIf.category,
                                            categoryWorth: typeMap.get(whatIf.category),
                                            totalPoints: whatIf.totalPoints,
                                            totalEarned: whatIf.totalEarned,
                                            date: whatIf.date
                                        })
                                        return assignments
                                        })
                                
                                        setAssignmentAdded(false)
                                setWhatIf({
                                        name: "Delulu Assignment",
                                        category: allAssignments[0].category,
                                        totalEarned: 0,
                                        totalPoints: 10,
                                        date: new Date().toLocaleDateString()
                                    })
                                

                            }}>Add What if</p>
                            </div>
                            
                            </div>
                            <div className="ml-auto border-l-2 pl-4">

                            <p className="font-2 font-medium sm:block hidden">{whatIf.date}</p>
                            </div>
                        

                        </div>

                        )}



                    {(allAssignments != (undefined)) && (allAssignments.length > 0) && (
                        allAssignments.map((assignment, i) => (
                            /* ((i%3===0) && (
                                <>
                                <amp-ad width="100vw" height="320"
     type="adsense"
     data-ad-client="ca-pub-7914515000030137"
     data-ad-slot="7201873916"
     data-auto-format="rspv"
     data-full-width="">
  <div overflow=""></div>
</amp-ad>
</>
                            )) */
                            ((assignment.name != "fadledme")) && (
                                <div className="sm:p-5 p-3 rounded-lg flex flex-row items-center  relative bg-secondary text-base-100" key={i}>
                            <div id={i} className="absolute top-0 right-0 px-1 bg-primary rounded-tr-lg rounded-bl-lg  text-white py-0 hover:bg-blue-600" onClick={removeItem}>

                                <svg id={i} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
  <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
</svg>

                            </div>
                            <div className="text-center sm:pr-4 pr-2 border-r-2">
                            <p className="font-1 text-lg text-red- font-bold text-red-500">{parseFloat((assignment.totalEarned/assignment.totalPoints) * 100).toFixed(2)}%</p>
                            <p className="font-1 text-sm font-semibold text-red-500">
                            <input id={i} className="text w-8 inline-block bg-black text-center rounded-full " value={assignment.totalEarned} onChange={(e) => {
                                // The issue with this is that you cannot be decimals in this system or else the entire thing crashes.
                                if (true) {
                                    setAllAssignments(assignments => {
                                        let modifyAssignments = [...assignments]
                                        if (isNaN(e.target.value)) {
                                            modifyAssignments[i].totalEarned = e.target.value
                                        } else {
                                            parseFloat(modifyAssignments[i].totalEarned = e.target.value)
                                        }
                                        return modifyAssignments
                                    })
                                }
                                
                                // if (true) {
                                //     if ((Number(e.target.value) < Number(allAssignments[i].totalPoints+1)) || (e.target.value === ".")) {
                                //         setAllAssignments(assignments => {
                                //         let modifyAssignments = [...assignments]
                                //         if (e.target.value == "") {
                                //             modifyAssignments[i].totalEarned = 0
                                //         } else {
                                //             modifyAssignments[i].totalEarned = parseInt(e.target.value)
                                //         }
                                        
                                //         return modifyAssignments
                                //     })
                                //     } else {
                                //         setAllAssignments(assignments => {
                                //         let modifyAssignments = [...assignments]
                                //         modifyAssignments[i].totalEarned = parseInt(modifyAssignments[i].totalPoints)
                                //         return modifyAssignments
                                //     })
                                        
                                //     }
                                    
                                // }


                                }}
                             />/
                             <input id={i} className="text w-8 inline-block bg-black text-center rounded-full" value={assignment.totalPoints} onChange={(e) => {
                                if (true) {
                                    setAllAssignments(assignments => {
                                        let modifyAssignments = [...assignments]
                                        modifyAssignments[i].totalPoints = e.target.value
                                        return modifyAssignments
                                    })
                                }
                                }}
                             />
                             </p>

                            </div>
                            <div className="sm:pl-4 pl-2">
                            <p className="font-2 text-base font-extrabold">{assignment.name}</p>
                            
                            <select id={i} className=" p-1 font-2 font-light text-sm  bg-primary " value={assignment.category} onChange={(e) => {
                                setAllAssignments((prevGrade) => {
                                    const prevAssignments = [...prevGrade]
                                    prevAssignments[i].category = e.target.value
                                    // console.log(typeMap.get(e.target.value))
                                    const newCategory = typeMap.get(e.target.value)
                                    prevAssignments[i].categoryWorth = newCategory
                                    return prevAssignments
                                })
                            }}>
                                {points.map((type, i) => (
                                    <option value={type.assignmentType} key={i}>{type.assignmentType}</option>
                                ))}
                                    
                                
                                
                            </select>
                            </div>
                            <div className="ml-auto border-l-2 pl-4">
                            <p className="font-2 font-medium sm:block hidden">{new Date(assignment.date).toLocaleDateString("en-US")}</p>
                            </div>
                        

                        </div>

                            )
                        

                    ))
                    )}
                    
                        

                    </div>
                    )}
                    

                </div>
                </div>
                
                </div>
                <div className=" w-4/6 mx-auto mt-16 sm:block hidden">
                <Footer />
                </div>
                
        </div>
        
        
        



        </>
    )
}

export default Card