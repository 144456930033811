import React from "react";





function Footer(props) {





    return (



        <>
        <div className="py-10 w-full bg-primary">

        <ul>
                <li className="my-1">
                <p className="font-2 underline-offset-4">A <u className="font-extrabold">Hernandez Production</u></p>
                </li>
                <li className="my-1">
                <p className="font-1">Copyright © {new Date().getFullYear() }</p>
                    </li>
                    <li className="my-1">
                    <p className="font-1 ">Email: <a href="mailto:maracuchoamericano@gmail.com" className="underline underline-offset-4">Email</a></p>
                    </li>
            </ul> 



            {/* <ul>
                <li className="my-1">
                <p className="font-2 underline-offset-4">A <u className="font-extrabold">Hernandez Production</u></p>
                </li>
                <li className="my-1">
                <p className="font-1">Copyright © {new Date().getFullYear() }</p>
                    </li>
                    <li className="my-1">
                    <p className="font-1 ">Email: <a href="mailto:maracuchoamericano@gmail.com" className="underline underline-offset-4">Email</a></p>
                    </li>
            </ul> */}
        
        
       

        </div>










        </>
    )
}



export default Footer