import React from "react";





function Navbar(props) {


    


    return (



        <>
            <div className="sticky top-0 left-0 w-full h-full z-30 navbar bg-secondary text-base-100">
                <div className="flex flex-row py-5 w-5/6 mx-auto h-full">

                <div>
                <p className="font-1 text-2xl py-2 select-none cursor-pointer" onClick={() => props.setCurrentScreen("Index")}>GradeVue</p>
                </div>

                {(props.name != undefined) && (
                    <div className="ml-auto sm:block hidden">

                    <p className="font-1 text-lg py-2">Hello, {props.name}!</p>
                    </div>

                    
                )}
                {(props.name == undefined) && (
                    <div className="ml-auto">
                        <button onClick={() => props.setCurrentScreen("Login")} className="btn btn-primary btn-md" >
                            <p className="font-1 text-base-100 ">Login</p>
     

                        </button>
                    </div>
                )}
                
                
                
                
                
                
                </div>
                
                
            </div>
            {/* <hr className="h-1 rounded-full w-4/6 mx-auto bg-black border-black"/> */}
                



        </>
    )
}



export default Navbar