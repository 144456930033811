import React, {useState} from "react"

import Index from "./Index"
import Login from "./Login"
import Dashboard from "./Dashboard"




function App(props) {


    const [endpoint, setEndpoint] = useState("")
    const [currentScreen, setCurrentScreen] = useState("Index")
    const [assignmentData, setAssignmentData] = useState("")



    return (

        <>


        {(currentScreen == "Index") && (
            <Index setCurrentScreen={setCurrentScreen} />
        )}
        {(currentScreen == "Login") && (
            <Login setCurrentScreen={setCurrentScreen} setAssignmentData={setAssignmentData} setEndpoint={setEndpoint} />
        )}

        {(currentScreen == "Dashboard") && (
            <Dashboard setCurrentScreen={setCurrentScreen} assignmentData={assignmentData} endpoint={endpoint} />

        )}

        

        
        </>
    )
}


export default App