import React, {useState, useEffect} from "react";
import Navbar from "./Navbar.jsx"
import Footer from "./Footer.jsx"



const listOfQuotes = ["The only way to do great work is to love what you do. - Steve Jobs",
"Success is not final, failure is not fatal: It is the courage to continue that counts. - Winston Churchill",
"Innovation distinguishes between a leader and a follower. - Steve Jobs",
"Believe you can and you're halfway there. - Theodore Roosevelt",
"The future belongs to those who believe in the beauty of their dreams. - Eleanor Roosevelt",
"Strive not to be a success, but rather to be of value. - Albert Einstein",
"The only thing standing between you and your goal is the story you keep telling yourself as to why you can't achieve it. - Jordan Belfort",
"You miss 100% of the shots you don't take. - Wayne Gretzky",
"The way to get started is to quit talking and begin doing. - Walt Disney",
"Your time is limited, don't waste it living someone else's life. - Steve Jobs",
"Success is walking from failure to failure with no loss of enthusiasm. - Winston Churchill",
"It does not matter how slowly you go as long as you do not stop. - Confucius",
"Everything you can imagine is real. - Pablo Picasso",
"What you get by achieving your goals is not as important as what you become by achieving your goals. - Zig Ziglar",
"You are never too old to set another goal or to dream a new dream. - C.S. Lewis",
"Don't watch the clock; do what it does. Keep going. - Sam Levenson",
"Our greatest glory is not in never falling, but in rising every time we fall. - Confucius",
"The only limit to our realization of tomorrow will be our doubts of today. - Franklin D. Roosevelt",
"The best time to plant a tree was 20 years ago. The second best time is now. - Chinese Proverb",
"If you can dream it, you can do it. - Walt Disney",
"The secret of getting ahead is getting started. - Mark Twain",
"It's not whether you get knocked down, it's whether you get up. - Vince Lombardi",
"People who are crazy enough to think they can change the world, are the ones who do. - Rob Siltanen",
"The only place where success comes before work is in the dictionary. - Vidal Sassoon",
"Whatever you are, be a good one. - Abraham Lincoln",
"You can't use up creativity. The more you use, the more you have. - Maya Angelou",
"Nothing is impossible, the word itself says 'I'm possible'! - Audrey Hepburn",
"The best revenge is massive success. - Frank Sinatra",
"Opportunities don't happen, you create them. - Chris Grosser",
"You miss 100% of the shots you don't take. - Wayne Gretzky",
"What you get by achieving your goals is not as important as what you become by achieving your goals. - Zig Ziglar",
"Believe you can and you're halfway there. - Theodore Roosevelt",
"The future belongs to those who believe in the beauty of their dreams. - Eleanor Roosevelt",
"Strive not to be a success, but rather to be of value. - Albert Einstein",
"The only way to do great work is to love what you do. - Steve Jobs",
"Success is not final, failure is not fatal: It is the courage to continue that counts. - Winston Churchill",
"Innovation distinguishes between a leader and a follower. - Steve Jobs",
"The only thing standing between you and your goal is the story you keep telling yourself as to why you can't achieve it. - Jordan Belfort"
]
const apiEndpoint = "https://api.gradevue.com/doeverything"
function Login(props) {
    const [password, setPassword] = useState("")
    const [username, setUsername] = useState("")
    const [county, setCounty] = useState("https://md-mcps-psv.edupoint.com")
    const [loading, setLoading] = useState(false)

    const [formError, setFormError] = useState("")

    const processForm = async() => {
        const data = {
            endpoint: county,
            password: password,
            username: username
        }

        const response = await fetch(apiEndpoint, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            
            body: JSON.stringify(data)
            
        })
        return response.json()
    }


    const submitForm = () => {
        setLoading(true)
        processForm().then((response) => {
            if (response.message === "invalid credentials") {
                console.log("wrong password")

                setFormError("Invalid Credentials")
                // wrong password or username
            } else if (response.message === "invalid") {
                setFormError("Something went wrong")
                // console.log("invalid api request")
                // something went wrong
            } else if (response.code === "ok") {
                props.setAssignmentData(response)


                props.setEndpoint(county)
                props.setCurrentScreen("Dashboard")
                





                // console.log("success")
                // it all went well
            } else {
                setFormError("Something went wrong")
                // something went wrong
            }

            setLoading(false)

        })
    }

    useEffect(() => {

        setTimeout(() => {
            setFormError("")
        }, 3000)
    }, [formError])





    






    return (


        <>

        
            
        {(loading) && (
            <>
            <div className="w-screen h-screen absolute bg-primary opacity-80 z-40"></div>
            <div className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-40">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-40 h-40 animate-spin text-white">
                <path fillRule="evenodd" d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 0 0-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 0 0-2.282.819l-.922 1.597a1.875 1.875 0 0 0 .432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 0 0 0 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 0 0-.432 2.385l.922 1.597a1.875 1.875 0 0 0 2.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 0 0 2.28-.819l.923-1.597a1.875 1.875 0 0 0-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 0 0 0-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 0 0-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 0 0-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 0 0-1.85-1.567h-1.843ZM12 15.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z" clipRule="evenodd" />
            </svg>
            <p className="text-center text-base-100 text-2xl animate-bounce font-2">Logging you in</p>
           


            </div>
            <div className="absolute z-40 bottom-12 left-[50%] translate-x-[-50%] text-base-100 font-1">
            <p className="font-semibold">"{listOfQuotes[Math.floor(Math.random()*(listOfQuotes.length-1))]}"</p>
            </div>
            
            </>
            

        )}
        
        {(formError != "") && (
                    <div className="fixed left-[50%] translate-x-[-50%] top-16 z-50 bg-black rounded-lg border-2 border-red-500 text-white">
                        <div className="px-5 py-2">
                            <p className="font-1">Invalid credentials</p>
                        </div>
                    </div>
                )}

       
        <div className="bg-primary w-full min-h-screen relative">
            <div className="bg-secondary ">
                <div className="w-5/6 mx-auto">
                <Navbar setCurrentScreen={props.setCurrentScreen} color="#f4f3ee" />
                </div>
                

            </div>
            
            
            <div className="sm:mx-auto sm:w-4/6 w-full relative">
                
                
                

                <div className="mx-auto h-fit sm:w-2/6 min-w-[350px] w-5/6 p-2  border-gray-200 shadow-md text-base-100 bg-secondary rounded-lg mt-[10%]">



                    <div className="m-4">
                        <p className="font-1 text-3xl mx-auto w-5/6 font-bold text-center">Sign into your account</p>
                        <div className="py-2 bg-primary m-2 rounded-lg">
                        <div className="mx-auto w-5/6 my-5 ">
                            <p className="font-1 my-1">Username</p>
                            <input value={username} maxLength="30" onChange={(e) => setUsername(e.target.value)} type="text" className="input w-full font-1  text-base-100 bg-secondary input-secondary border-0" placeholder="12345" />
                        </div>
            
                        <div className="mx-auto w-5/6 my-5">
                            <p className="font-1 my-1">Password</p>
                            <input value={password} maxLength="30" onChange={(e) => setPassword(e.target.value)} type="password" className="input w-full font-1  text-base-100 bg-secondary input-secondary border-0" placeholder="●●●●●●●" />
                        </div>
                        </div>
                            <div className=" bg-primary mt-3 rounded-lg w-fit mx-auto p-2">
                            <p className="text-center font-1 text-base mb-1  font-semibold">School County:</p>
                            <div className="cmb text-center">
                            
                            
                            <select name="county" className="font-2 w-4/6 px-2 py-1 rounded-lg bg-primary select select-secondary" value={county} onChange={(event) => setCounty(event.target.value)}>
                            <option value="https://az-acce.edupoint.com/">A Center for Creative Education</option>
                            <option value="https://mi-atsec.edupoint.com/">ATS Educational Consulting dba My Virtual Academy</option>
                            <option value="https://az-asdb.edupoint.com">AZ Schools for the Deaf and Blind</option>
                            <option value="https://az-alc.edupoint.com/">Accelerated Learning Center</option>
                            <option value="https://rt2.region1.k12.mn.us/rt2910">Ada-Borup-West School District</option>
                            <option value="https://or-asd.edupoint.com/">Adrian School District</option>
                            <option value="https://afsd.edupoint.com">Agua Fria Union High School District</option>
                            <option value="https://az-aesd63.edupoint.com/">Aguila Elementary School District #63</option>
                            <option value="HTTPS://sis-psvue1.tnk12.gov/ALCS">Alamo City School</option>
                            <option value="https://synergypvue.aps4kids.org">Alamogordo Public Schools</option>
                            <option value="https://rt3.region1.k12.mn.us/rt0241">Albert Lea Area Schools</option>
                            <option value="https://mystudentlogin.aps.edu">Albuquerque Public Schools</option>
                            <option value="https://studentvue.geneseeisd.org/acs/">Alcona Community Schools</option>
                            <option value="https://rt4.region1.k12.mn.us/rt0206">Alexandria School District 206</option>
                            <option value="https://parentvue.alhambraesd.org/">Alhambra Elementary School District</option>
                            <option value="https://pvue.alpenaschools.com/aps_pvue">Alpena Public Schools</option>
                            <option value="https://studentvue.geneseeisd.org/esd/">Alpena-Montmorency-Alcona Educational Service District</option>
                            <option value="https://az-aesd7.edupoint.com/">Alpine Elementary School District #7</option>
                            <option value="https://sdhome.sdcoe.net/Alpine">Alpine Union School District</option>
                            <option value="https://az-alt.edupoint.com/">Altar Valley School District</option>
                            <option value="https://az-aa.edupoint.com/">Ambassador Academy</option>
                            <option value="https://or-amit.edupoint.com">Amity School District</option>
                            <option value="https://or-aes.edupoint.com/">Annex School District</option>
                            <option value="https://SynergyPortal.anoka.k12.mn.us">Anoka-Hennepin School District</option>
                            <option value="https://antelope.apscc.org/">Antelope Union High School</option>
                            <option value="https://synergyweb.ajusd.org">Apache Junction Unified School District</option>
                            <option value="https://studentvue.geneseeisd.org/aac">Arbor Academy</option>
                            <option value="https://ca-aesd.edupoint.com/">Arcata Elementary School District</option>
                            <option value="https://aaec.apscc.org">Arizona Agribusiness and Equine Center (AAEC)</option>
                            <option value="https://az-acyr.edupoint.com/">Arizona Call-A-Teen Youth Resources</option>
                            <option value="https://az-alp.edupoint.com/">Arizona Language Preparatory</option>
                            <option value="https://asa.apscc.org/">Arizona School for the Arts</option>
                            <option value="https://az-aesd47.edupoint.com/">Arlington Elementary School District #47</option>
                            <option value="https://va-arl-psv.edupoint.com">Arlington Public Schools</option>
                            <option value="https://or-arl.edupoint.com">Arlington School District</option>
                            <option value="https://rt1.region1.k12.mn.us/rt0261">Ashby Public School</option>
                            <option value="https://pvue6.region1.k12.mn.us/rt4184">Aspen Academy</option>
                            <option value="https://student-astoria.cascadetech.org/astoria">Astoria School District</option>
                            <option value="https://parent-athenaweston.cascadetech.org/athenaweston/">Athena-Weston School District</option>
                            <option value="https://sis-psvue2.tnk12.gov/acs/">Athens City School District 541</option>
                            <option value="https://studentvue.geneseeisd.org/Athr">Atherton Community Schools</option>
                            <option value="https://mn4011vue.tiescloud.net/mn4011">Athlos Leadership Academy</option>
                            <option value="https://studentvue.geneseeisd.org/aga">Augusta Academy</option>
                            <option value="https://az-aesd44.edupoint.com">Avondale Elementary School District #44</option>
                            <option value="https://rt1.region1.k12.mn.us/rt0162">Bagley Independent School District #162</option>
                            <option value="https://me-bsd.edupoint.com/">Baileyville School District</option>
                            <option value="https://ca-bpusd-psv.edupoint.com">Baldwin Park Unified School District</option>
                            <option value="https://parents.balsz.org">Balsz Elementary School District #31</option>
                            <option value="https://student-banks.cascadetech.org/banks">Banks School District</option>
                            <option value="https://ca-bas.edupoint.com">Bassett Unified School District</option>
                            <option value="https://rt1.region1.k12.mn.us/rt4124">Beacon Academy Charter School</option>
                            <option value="https://bcs.apscc.org">Beaver Creek School District #26</option>
                            <option value="https://studentvue.beaverton.k12.or.us">Beaverton School District -</option>
                            <option value="https://ny-bcsd-psv.edupoint.com">Bedford Central School District</option>
                            <option value="https://studentvue.geneseeisd.org/Bchr">Beecher Community School District</option>
                            <option value="https://wa-bsd405-PSV.edupoint.com">Bellevue School District #405</option>
                            <option value="https://sv.bend.k12.or.us">Bend-LaPine Schools</option>
                            <option value="https://studentvue.geneseeisd.org/Bend">Bendle Public Schools</option>
                            <option value="https://studentvue.geneseeisd.org/Bcaa">Bendle-Carman Park Academy</option>
                            <option value="https://studentvue.geneseeisd.org/Btly">Bentley Community Schools</option>
                            <option value="HTTPS://sis-psvue1.tnk12.gov/BENCS">Benton County Schools</option>
                            <option value="https://wa-beth.edupoint.com">Bethel School District</option>
                            <option value="https://rt1.region1.k12.mn.us/rt0032">Blackduck Public Schools</option>
                            <option value="https://az-bed.edupoint.com/">Blue Elementary District</option>
                            <option value="https://ca-blu.edupoint.com/">Blue Lake Union Elementary</option>
                            <option value="https://az-blr.edupoint.com/">Blue Ridge Unified School District</option>
                            <option value="https://bvpo.bluevalleyk12.org">Blue Valley School District</option>
                            <option value="https://sdhome.sdcoe.net/Bonsall">Bonsall Unified School District</option>
                            <option value="https://sdhome.sdcoe.net/BorregoSprings">Borrego Springs Unified School District</option>
                            <option value="https://bowie.apscc.org">Bowie Unified School District #14</option>
                            <option value="https://az-bgcev.edupoint.com/">Boys &amp; Girls Club of the East Valley</option>
                            <option value="https://psv-bradford.tnk12.gov/bssd">Bradford Area School District</option>
                            <option value="https://az-bae.edupoint.com/">Bradley Academy of Excellence</option>
                            <option value="HTTPS://sis-psvue2.tnk12.gov/BCS">Bradley County Schools</option>
                            <option value="https://rt2.region1.k12.mn.us/rt0207">Brandon-Evansville Public Schools</option>
                            <option value="https://rt1.region1.k12.mn.us/rt0846">Breckenridge Public School</option>
                            <option value="https://pvue7.region1.k12.mn.us/rt0286">Brooklyn Center Community Schools</option>
                            <option value="https://buckeye.apscc.org">Buckeye Elementary School District #33</option>
                            <option value="https://vue.buhsd.org">Buckeye Union High School District</option>
                            <option value="https://pvue5.region1.k12.mn.us/rt0191">Burnsville-Eagan-Savage School District 191</option>
                            <option value="https://parent-burntriver.cascadetech.org/burntriver/">Burnt River School District 30J</option>
                            <option value="https://studentvue.geneseeisd.org/boc">Burr Oak Community Schools</option>
                            <option value="https://student-imesd.cascadetech.org/imesd/">CTA - Cascade Technology Alliance</option>
                            <option value="https://sdhome.sdcoe.net/Calexico">Calexico Unified School District</option>
                            <option value="https://student-camasvalley.cascadetech.org/camasvalley">Camas Valley School District 21</option>
                            <option value="https://rt3.region1.k12.mn.us/rt0852">Campbell-Tintah Public Schools</option>
                            <option value="https://synergy.clackesd.k12.or.us/CASD/">Canby School District</option>
                            <option value="https://pvue6.region1.k12.mn.us/rt4194">Cannon River STEM School</option>
                            <option value="https://az-ced.edupoint.com/">Canon Elementary District</option>
                            <option value="https://nm-car.edupoint.com">Carlsbad Municipal Schools</option>
                            <option value="https://studentvue.geneseeisd.org/Carm">Carman-Ainsworth Community Schools</option>
                            <option value="https://az-cesd-psv.edupoint.com">Cartwright School District</option>
                            <option value="https://az-cfsd16.edupoint.com">Catalina Foothills Unified School District #16</option>
                            <option value="https://student-centennial.cascadetech.org/centennial">Centennial School District</option>
                            <option value="https://studentvue.geneseeisd.org/ccp">Center for Countywide Programs</option>
                            <option value="https://vue.district6.org">Central Point School District No. 6</option>
                            <option value="https://az-champ.edupoint.com/">Champion Schools</option>
                            <option value="https://md-ccps-psv.edupoint.com/">Charles County Public Schools</option>
                            <option value="https://va-cps-psv.edupoint.com/">Chesapeake Public Schools</option>
                            <option value="HTTPS://sis-psvue1.tnk12.gov/CHCS">Chester County Schools</option>
                            <option value="https://VA-CHESTERFIELD-PSV.edupoint.com">Chesterfield County Public Schools</option>
                            <option value="https://az-cplc-psv.edupoint.com">Chicanos Por La Causa</option>
                            <option value="https://az-cplch-psv.edupoint.com">Chicanos Por La Causa Hiaki High School</option>
                            <option value="https://pvue9.region1.k12.mn.us/rt2144">Chisago Lakes School District</option>
                            <option value="https://rt3.region1.k12.mn.us/rt0771">Chokio-Alberta Public Schools</option>
                            <option value="https://synergy.clackesd.k12.or.us/CESD/">Clackamas ESD</option>
                            <option value="https://in-cpc-psv.edupoint.com">Clark-Pleasant Community School Corporation</option>
                            <option value="https://cjsd3.apscc.org">Clarkdale Jerome School District</option>
                            <option value="https://studentvue.geneseeisd.org/ccs">Clarkston Community Schools</option>
                            <option value="https://student-clatskanie.cascadetech.org/clatskanie">Clatskanie School District</option>
                            <option value="https://fl-clay-psv.edupoint.com">Clay County School District</option>
                            <option value="https://rt3.region1.k12.mn.us/rt2311">Clearbrook-Gonvick</option>
                            <option value="https://studentvue.geneseeisd.org/cscs">Climax-Scotts Community Schools</option>
                            <option value="https://rt2.region1.k12.mn.us/rt0592">Climax-Shelly School</option>
                            <option value="https://studentvue.geneseeisd.org/clio">Clio Area School District</option>
                            <option value="https://studentvue.cobbk12.org">Cobb County School District</option>
                            <option value="https://az-ccasd.edupoint.com/">Coconino County Accommodation School District</option>
                            <option value="https://psv-coffee.tnk12.gov/cof">Coffee County Schools</option>
                            <option value="https://synergy.crsk12.org">Colorado River Schools</option>
                            <option value="https://synergy.clackesd.k12.or.us/COSD/">Colton School District</option>
                            <option value="https://pvue8.region1.k12.mn.us/rt0013">Columbia Heights Public Schools</option>
                            <option value="https://ne-cps-psv.edupoint.com/">Columbus Public Schools</option>
                            <option value="https://synergyweb.cusd200.org">Community Unit School District 200</option>
                            <option value="https://pvue7.region1.k12.mn.us/rt4015">Community of Peace Academy</option>
                            <option value="https://or-con.edupoint.com">Condon School District</option>
                            <option value="https://az-cesd39.edupoint.com/">Continental Elementary School District #39</option>
                            <option value="https://or-coosbay.edupoint.com/">Coos Bay School District</option>
                            <option value="https://student-corbett.cascadetech.org/corbett">Corbett School District</option>
                            <option value="https://sdhome.sdcoe.net/CoronadoPathways">Coronado Pathways Charter School</option>
                            <option value="https://ca-cou.edupoint.com">Coronado Unified School District</option>
                            <option value="https://parent-cove.cascadetech.org/cove/">Cove School District</option>
                            <option value="https://crane.apscc.org">Crane Schools</option>
                            <option value="https://az-ca.edupoint.com/">Create Academy</option>
                            <option value="https://az-crsd.edupoint.com">Creighton School District</option>
                            <option value="HTTPS://sis-psvue1.tnk12.gov/CCS">Crockett County Schools</option>
                            <option value="https://or-ccsd.edupoint.com">Crook County School District</option>
                            <option value="https://az-ckesd41.edupoint.com/">Crown King Elementary School District #41</option>
                            <option value="https://d47.edupoint.com">Crystal Lake Community Consolidated School District 47</option>
                            <option value="https://parentvue.cusdk8.org">Cupertino Union School District</option>
                            <option value="https://student-daviddouglas.cascadetech.org/daviddouglas">David Douglas School District</option>
                            <option value="https://studentvue.geneseeisd.org/Davs">Davison Community Schools</option>
                            <option value="https://student-dayscreek.cascadetech.org/dayscreek">Days Creek Charter School</option>
                            <option value="https://or-day.edupoint.com/">Dayville School District</option>
                            <option value="HTTPS://sis-psvue1.tnk12.gov/DECCS">Decatur County Schools</option>
                            <option value="https://sdhome.sdcoe.net/Dehesa">Dehesa School District</option>
                            <option value="https://ca-dmusd.edupoint.com/">Del Mar Union School District</option>
                            <option value="https://pa-derry.edupoint.com">Derry Township School District</option>
                            <option value="https://az-dhcs.edupoint.com">Desert Heights Charter Schools</option>
                            <option value="https://student.dsusd.us/">Desert Sands Unified School District</option>
                            <option value="https://az-dscs.edupoint.com">Desert Sky Community School</option>
                            <option value="https://dsa.apscc.org">Desert Star Academy</option>
                            <option value="https://rt4.region1.k12.mn.us/rt2164">Dilworth-Glyndon-Felton Public Schools (ISD# 2164)</option>
                            <option value="https://il-dist.edupoint.com/PXP2_Login_Student.aspx">Distinctive Schools</option>
                            <option value="https://or-hesd.edupoint.com">Double O Elementary School</option>
                            <option value="https://or-hesd.edupoint.com">Drewsey Elementary School</option>
                            <option value="https://or-duf.edupoint.com">Dufur School District 29</option>
                            <option value="https://pvue8.region1.k12.mn.us/rt4153">Dugsi Academy Charter School</option>
                            <option value="HTTPS://sis-psvue1.tnk12.gov/DCS">Dyer County Schools</option>
                            <option value="HTTPS://sis-psvue1.tnk12.gov/DYCS">Dyersburg City Schools</option>
                            <option value="https://demojb.edupoint.com">EES Demo District</option>
                            <option value="https://sv.eaglepoint.sis.k12.or.us">Eagle Point School District 9 (Jackson County)</option>
                            <option value="https://az-ecaai.edupoint.com/">Early Career Academy Arizona Inc</option>
                            <option value="https://pvue9.region1.k12.mn.us/rt2580">East Central Schools</option>
                            <option value="https://rt2.region1.k12.mn.us/rt0595">East Grand Forks Public Schools</option>
                            <option value="https://pvue5.region1.k12.mn.us/rt4166">East Range Academy of Technology and Science</option>
                            <option value="https://ny-esm-psv.edupoint.com">Eastport South Manor Central School District</option>
                            <option value="https://student-echo.cascadetech.org/echo">Echo School District</option>
                            <option value="https://az-ea.edupoint.com/">Ed Ahead</option>
                            <option value="https://edison.apscc.org/">Edison School of Innovation</option>
                            <option value="http://98.175.215.20/ST2022">Edupoint Educational Systems - Internal</option>
                            <option value="https://sdhome.sdcoe.net/ElCentro">El Centro Elementary School District</option>
                            <option value="https://ca-emuhsd-psv.edupoint.com">El Monte Union High School District</option>
                            <option value="https://az-eesd.edupoint.com/">Elfrida Elementary School District</option>
                            <option value="https://parent-elgin.cascadetech.org/elgin/">Elgin School District</option>
                            <option value="https://ca-egusd-psv.edupoint.com/">Elk Grove Unified School District</option>
                            <option value="https://student-elkton.cascadetech.org/elkton">Elkton Charter School #34</option>
                            <option value="https://empower.apscc.org">Empower College Prep</option>
                            <option value="https://or-ent.edupoint.com">Enterprise School District</option>
                            <option value="https://sdhome.sdcoe.net/EscondidoChrt">Escondido Charter High School</option>
                            <option value="https://sdhome.sdcoe.net/EscondidoHigh">Escondido Union High School District</option>
                            <option value="https://nm-espd55.edupoint.com">Espanola Public School District #55</option>
                            <option value="https://az-eef.edupoint.com">Estrella Educational Foundation</option>
                            <option value="HTTPS://sis-psvue2.tnk12.gov/ECS">Etowah City School</option>
                            <option value="https://sv.4j.lane.edu">Eugene School District 4J</option>
                            <option value="https://studentvue.geneseeisd.org/eva">Evergreen Academy</option>
                            <option value="https://sisstudent.fcps.edu/SVUE">Fairfax County Public Schools</option>
                            <option value="https://grades.fwps.org">Federal Way Public Schools</option>
                            <option value="https://studentvue.geneseeisd.org/fent">Fenton Area Public Schools</option>
                            <option value="https://rt4.region1.k12.mn.us/rt0544">Fergus Falls Public Schools (ISD 544)</option>
                            <option value="https://rt1.region1.k12.mn.us/rt0599">Fertile-Beltrami Public School</option>
                            <option value="https://fusd1.apscc.org">Flagstaff Unified School District</option>
                            <option value="https://mn4235vue.tiescloud.net/mn4235">Flex Academy</option>
                            <option value="https://studentvue.geneseeisd.org/Flnt">Flint Community Schools</option>
                            <option value="https://fusdaz.apscc.org">Florence Unified School District No. 1</option>
                            <option value="https://studentvue.geneseeisd.org/Flsh">Flushing Community Schools</option>
                            <option value="https://studentvue.geneseeisd.org/foa">Forest Academy</option>
                            <option value="https://student-forestgrove.cascadetech.org/forestgrove">Forest Grove School District</option>
                            <option value="https://pvue6.region1.k12.mn.us/rt0831">Forest Lake Area Schools</option>
                            <option value="https://or-fsd.edupoint.com">Fossil School District</option>
                            <option value="https://rt1.region1.k12.mn.us/rt0601">Fosston School District</option>
                            <option value="https://az-ffai.edupoint.com/">Founding Fathers Academies Inc</option>
                            <option value="https://or-frcs.edupoint.com/">Four Rivers Community School</option>
                            <option value="https://in-flcs.edupoint.com/">Frankton-Lapel Community Schools</option>
                            <option value="https://rt4.region1.k12.mn.us/rt0023">Frazee-Vergas Public Schools (ISD # 23)</option>
                            <option value="https://az-fmusd6.edupoint.com/">Fredonia-Mocassin Unified School District #6</option>
                            <option value="https://parentvue.freedomacademyaz.org/">Freedom Academy</option>
                            <option value="https://or-hesd.edupoint.com">Frenchglen Elementary School</option>
                            <option value="https://studentvue.geneseeisd.org/GITV">GITV - Live Distance Learning</option>
                            <option value="https://synergy.gesd32.org">Gadsden Elementary School District #32</option>
                            <option value="https://synergy.micase.org/gcs">Galesburg-Augusta Community Schools</option>
                            <option value="https://nm-gmc-psv.edupoint.com/">Gallup McKinley County Schools</option>
                            <option value="https://ca-galt-psv.edupoint.com">Galt Joint Union Elementary School District</option>
                            <option value="https://student-gaston.cascadetech.org/gaston">Gaston School District</option>
                            <option value="https://parentvue.geneseeisd.org/gci">Genesee Career Institute</option>
                            <option value="https://studentvue.geneseeisd.org/GGEC">Genesee Early College</option>
                            <option value="https://studentvue.geneseeisd.org/GISD">Genesee Intermediate School District (GISD)</option>
                            <option value="https://studentvue.geneseeisd.org/gsa">Genesee STEM Academy</option>
                            <option value="https://studentvue.geneseeisd.org/Gens">Genesee School District</option>
                            <option value="https://ggpa.edupoint.com">George Gervin Preparatory Academy</option>
                            <option value="https://az-gila.edupoint.com">Gila Bend Unified School District</option>
                            <option value="https://psv-giles.tnk12.gov/giles">Giles County School System</option>
                            <option value="https://synergy.clackesd.k12.or.us/GLSD/">Gladstone School District</option>
                            <option value="https://parent.gesd40.org">Glendale Elementary School District</option>
                            <option value="https://student-glendale.cascadetech.org/glendale">Glendale School District No 77</option>
                            <option value="https://StudentVUE.guhsdaz.org">Glendale Union High School District</option>
                            <option value="https://gsd.edupoint.com">Glide School District</option>
                            <option value="https://spportal.westmihosting.com/GHPS_pxp">Godwin Heights Public Schools</option>
                            <option value="https://ca-gol.edupoint.com/">Goleta Union Elementary School District</option>
                            <option value="https://studentvue.geneseeisd.org/Good">Goodrich Area Schools</option>
                            <option value="https://rt3.region1.k12.mn.us/rt0561">Goodridge Independent School District #561</option>
                            <option value="https://in-gei.edupoint.com">Goodwill Education Initiatives</option>
                            <option value="https://ky-goodwill.edupoint.com">Goodwill Industries of Kentucky</option>
                            <option value="HTTPS://sis-psvue2.tnk12.gov/GCS">Grainger County Schools</option>
                            <option value="https://sis.geneseeisd.org/gba">Grand Blanc Academy</option>
                            <option value="https://studentvue.geneseeisd.org/Gbcs">Grand Blanc Community Schools</option>
                            <option value="https://pvue.ghaps.org/pxp">Grand Haven Area Public Schools</option>
                            <option value="https://ne-gips.edupoint.com">Grand Island Public Schools</option>
                            <option value="http://synergyvue.grcs.org">Grand Rapids Christian Schools</option>
                            <option value="https://family.grps.org/PXP">Grand Rapids Public Schools</option>
                            <option value="https://or-grant.edupoint.com/">Grant School District  #3</option>
                            <option value="https://nm-gccs.edupoint.com">Grants Cibola County Schools</option>
                            <option value="https://rt1.region1.k12.mn.us/rt2683">Greenbush Middle River School District</option>
                            <option value="https://me-gren.edupoint.com">Greenbush School Department</option>
                            <option value="https://pvue.gpsjackets.org/PVUE">Greenville Public Schools</option>
                            <option value="https://student-gresham.cascadetech.org/gresham">Gresham-Barlow School District</option>
                            <option value="https://rt3.region1.k12.mn.us/rt0447">Grygla Public School</option>
                            <option value="https://studentvue.geneseeisd.org/glc">Gull Lake Community Schools</option>
                            <option value="https://apps.gwinnett.k12.ga.us/sismobile/spvue">Gwinnett County Public Schools</option>
                            <option value="https://az-hesd3.edupoint.com/">Hackberry Elementary School District #3</option>
                            <option value="https://nm-hag.edupoint.com/">Hagerman Municipal Schools</option>
                            <option value="https://parentvue.hanfordesd.org/">Hanford Elementary School District</option>
                            <option value="HTTPS://sis-psvue1.tnk12.gov/HACS">Hardeman County Schools</option>
                            <option value="https://psv-hardin.tnk12.gov/hard">Hardin County Schools</option>
                            <option value="https://or-hcsd3.edupoint.com/">Harney County School District #3</option>
                            <option value="https://or-hesd.edupoint.com">Harney Education Service District</option>
                            <option value="https://or-hsd.edupoint.com">Harper School District</option>
                            <option value="https://mn4192vue.tiescloud.net/mn4192">Harvest Preparatory School</option>
                            <option value="https://ne-hps.edupoint.com">Hastings Public Schools</option>
                            <option value="https://sdhome.sdcoe.net/Heber">Heber Elementary School District</option>
                            <option value="https://ca-helix.edupoint.com">Helix Charter High School</option>
                            <option value="https://parent-helix.cascadetech.org/helix/">Helix School District</option>
                            <option value="HTTPS://sis-psvue1.tnk12.gov/HENCS">Henderson County Schools</option>
                            <option value="HTTPS://sis-psvue1.tnk12.gov/HCS">Henry County Schools</option>
                            <option value="https://sdhome.sdcoe.net/HeritageChrt">Heritage Charter School</option>
                            <option value="https://sdhome.sdcoe.net/Heritagedigital">Heritage Digital Academy</option>
                            <option value="https://rt3.region1.k12.mn.us/rt0264">Herman-Norcross Community School</option>
                            <option value="https://az-hfs.edupoint.com">Highland Free School</option>
                            <option value="https://wa-high-psv.edupoint.com">Highline Public Schools</option>
                            <option value="https://parentvue.husd.org/">Higley Unified School District</option>
                            <option value="https://hillcrest.apscc.org">Hillcrest Academy</option>
                            <option value="https://myvue.cascadetech.org/hillsboro/">Hillsboro School District</option>
                            <option value="https://fl-hcps-psv.edupoint.com">Hillsborough County Public Schools</option>
                            <option value="https://pvue9.region1.k12.mn.us/rt2165">Hinckley-Finlayson Public Schools</option>
                            <option value="https://sdhome.sdcoe.net/holtville">Holtville USD</option>
                            <option value="https://sisparents.hcpss.org">Howard County Public School System</option>
                            <option value="https://pvue10.region1.k12.mn.us/rt2687">Howard Lake-Waverly-Winsted Public Schools</option>
                            <option value="https://mi-hs.edupoint.com">Huda School</option>
                            <option value="https://humboldtunified.apscc.org">Humboldt Unified School District</option>
                            <option value="https://or-hun.edupoint.com/">Huntington School District</option>
                            <option value="https://parent-imbler.cascadetech.org/imbler/">Imbler School District</option>
                            <option value="https://sdhome.sdcoe.net/Imperial">Imperial Unified School District</option>
                            <option value="https://az-is.edupoint.com/">Incito Schools</option>
                            <option value="https://il-ipsd-psv.edupoint.com/">Indian Prairie School District 204</option>
                            <option value="https://sdhome.sdcoe.net/IntegrityCharter">Integrity Charter School</option>
                            <option value="https://mi-ibs.edupoint.com">Inter-City Baptist School</option>
                            <option value="https://student-imesd.cascadetech.org/imesd">InterMountain Education Service District</option>
                            <option value="https://mn-id287.edupoint.com/">Intermediate District 287</option>
                            <option value="https://parent-ione.cascadetech.org/ione/">Ione School District</option>
                            <option value="https://parents.isaacschools.org/">Isaac Elementary School District</option>
                            <option value="https://az-joc.edupoint.com">JO Combs Unified School District No. 44</option>
                            <option value="https://az-jsphs.edupoint.com/">James Sandoval Preparatory High School</option>
                            <option value="https://sdhome.sdcoe.net/Jamul">Jamul-Dulzura Union School District</option>
                            <option value="https://portal.jsd.k12.ca.us/">Jefferson Elementary School District</option>
                            <option value="https://genesis.juhsd.net/pxp">Jefferson Union High School District</option>
                            <option value="https://student-jewell.cascadetech.org/jewell">Jewell School District #8</option>
                            <option value="https://mo-jos.edupoint.com/">Joplin Schools</option>
                            <option value="https://or-jvsd.edupoint.com/">Jordan Valley School District</option>
                            <option value="https://or-jos.edupoint.com">Joseph School District</option>
                            <option value="https://sdhome.sdcoe.net/JulianHigh">Julian Union High School District</option>
                            <option value="https://sdhome.sdcoe.net/julianelementary">Julian Union School District</option>
                            <option value="https://studentvue.geneseeisd.org/kam">Kalamazoo Area Mathematics &amp; Science Center</option>
                            <option value="https://synergypvue.micase.org/kcs">Kalamazoo Christian School Association</option>
                            <option value="https://rt1.region1.k12.mn.us/rt4118">Kaleidoscope Charter School</option>
                            <option value="https://kayenta.apscc.org">Kayenta Unified School District</option>
                            <option value="https://studentvue.geneseeisd.org/Kear">Kearsley Community Schools</option>
                            <option value="https://rt1.region1.k12.mn.us/rt0036">Kelliher Public School</option>
                            <option value="https://synergypvue.kentwoodps.org">Kentwood Public Schools</option>
                            <option value="https://connect.kernhigh.org">Kern Unified High School District</option>
                            <option value="https://az-ksi.edupoint.com/">Kestrel Schools Inc</option>
                            <option value="https://kaol.apscc.org">Kingman Academy of Learning</option>
                            <option value="https://studentvue.kusd.org">Kingman Unified School District #20</option>
                            <option value="https://mn2137vue.tiescloud.net/mn2137">Kingsland Public Schools</option>
                            <option value="https://rt1.region1.k12.mn.us/rt2171">Kittson Central</option>
                            <option value="https://student-knappa.cascadetech.org/knappa">Knappa School District</option>
                            <option value="https://az-ksd-psv.edupoint.com">Kyrene School District</option>
                            <option value="https://or-lag.edupoint.com">La Grande School District</option>
                            <option value="https://latierra.apscc.org">La Tierra Community Schools</option>
                            <option value="https://psv-lake.tnk12.gov/lake">Lake County Schools</option>
                            <option value="https://studentvue.geneseeisd.org/Lkfn">Lake Fenton Community Schools</option>
                            <option value="https://az-lhusd1.edupoint.com">Lake Havasu Unified School District #1</option>
                            <option value="https://synergy.clackesd.k12.or.us/LOSD/">Lake Oswego School District</option>
                            <option value="https://rt2.region1.k12.mn.us/rt2889">Lake Park Audubon ISD 2889</option>
                            <option value="https://rt1.region1.k12.mn.us/rt0390">Lake of the Woods Community Schools</option>
                            <option value="https://studentvue.geneseeisd.org/Lkvl">LakeVille Community Schools</option>
                            <option value="https://parentvue.geneseeisd.org/lcs">Lakeside Charter School</option>
                            <option value="https://sdhome.sdcoe.net/Lakeside">Lakeside Union School District</option>
                            <option value="https://pvue6.region1.k12.mn.us/rt2167">Lakeview School District</option>
                            <option value="http://genesis.lakewood.k12.mi.us">Lakewood Public Schools</option>
                            <option value="https://rt3.region1.k12.mn.us/rt0356">Lancaster School District 356</option>
                            <option value="https://synergy.lansingschools.net/production/">Lansing School District</option>
                            <option value="https://synergy.lapeerisd.org/">Lapeer County Intermediate School District</option>
                            <option value="https://rt1.region1.k12.mn.us/rt0306">Laporte School District #306</option>
                            <option value="https://parentvue.lcps.k12.nm.us">Las Cruces Public School District</option>
                            <option value="https://laveenvue.apscc.org">Laveen Elementary School District #59</option>
                            <option value="https://az-lea.edupoint.com/">Leading Edge Academy</option>
                            <option value="https://lfpaa.edupoint.com">Learning Foundation</option>
                            <option value="https://az-lae.edupoint.com/">Leman Academy of Excellence</option>
                            <option value="https://pvue8.region1.k12.mn.us/rt0424">Lester Prairie Independent School District</option>
                            <option value="https://mn4233vue.tiescloud.net/mn4233">Level Up Academy</option>
                            <option value="https://sis-lexington.tnk12.gov/lcs">Lexington City Schools</option>
                            <option value="https://az-lib.edupoint.com/">Liberty Elementary School District</option>
                            <option value="https://or-lincoln-psv.edupoint.com">Lincoln County School District</option>
                            <option value="https://synergyvue.lps.org">Lincoln Public Schools</option>
                            <option value="https://studentvue.geneseeisd.org/Lind">Linden Community Schools</option>
                            <option value="https://pvue8.region1.k12.mn.us/rt4183">Lionsgate Academy</option>
                            <option value="https://az-litch-psv.edupoint.com">Litchfield Elementary School District</option>
                            <option value="https://sdhome.sdcoe.net/LiteracyFirst">Literacy First Charter Schools</option>
                            <option value="https://littleton.apscc.org">Littleton Elementary School District #65</option>
                            <option value="https://ca-lbusd-psv.edupoint.com">Long Beach Unified School District</option>
                            <option value="https://or-lcsd.edupoint.com/">Long Creek School District</option>
                            <option value="https://portal.lcps.org">Loudoun County Public Schools</option>
                            <option value="https://nm-lms.edupoint.com">Loving Municipal Schools</option>
                            <option value="studentvue.geneseeisd.org/loyola">Loyola High School</option>
                            <option value="https://mo-mers.edupoint.com/">MERS Goodwill</option>
                            <option value="https://sdsis2.sdcoe.net/magnolia">Magnolia Union ESD</option>
                            <option value="https://rt2.region1.k12.mn.us/rt0432">Mahnomen Public Schools</option>
                            <option value="https://pvue6.region1.k12.mn.us/rt0832">Mahtomedi Public Schools ISD #832</option>
                            <option value="https://maine.apscc.org">Maine Consolidated School</option>
                            <option value="https://genesis.maranausd.org">Marana Unified School District</option>
                            <option value="https://az-mcrsd-psv.edupoint.com/">Maricopa County Regional School District</option>
                            <option value="https://musd20.apscc.org">Maricopa Unified School District #20</option>
                            <option value="HTTPS://sis-psvue1.tnk12.gov/MARCS">Marion County Schools</option>
                            <option value="https://rt1.region1.k12.mn.us/rt0441">Marshall County Central Schools</option>
                            <option value="https://psv-marshall.tnk12.gov/mar">Marshall County Schools</option>
                            <option value="https://az-mcs.edupoint.com">Masada Charter School</option>
                            <option value="https://ak-matsu-psv.edupoint.com">Matanuska-Susitna School District</option>
                            <option value="https://psv-maury.tnk12.gov/mau">Maury County Public Schools</option>
                            <option value="https://sdhome.sdcoe.net/mccabe">McCabe Elementary</option>
                            <option value="HTTPS://sis-psvue2.tnk12.gov/MCCS">McMinn County Schools</option>
                            <option value="https://psv-mcnairy.tnk12.gov/mcn">McNairy County Schools</option>
                            <option value="https://sdhome.sdcoe.net/meadows">Meadows Union ESD</option>
                            <option value="https://or-med-psv.edupoint.com">Medford School District</option>
                            <option value="HTTPS://sis-psvue2.tnk12.gov/MCS">Meigs County Schools</option>
                            <option value="https://rt2.region1.k12.mn.us/rt0740">Melrose Public Schools</option>
                            <option value="https://sis.d51schools.org">Mesa County Valley School District No.51</option>
                            <option value="https://parent.mpsaz.org">Mesa Public Schools</option>
                            <option value="https://pvue5.region1.k12.mn.us/rt4005">Metro Deaf School</option>
                            <option value="https://pa-miff-psv.edupoint.com">Mifflinburg Area School District</option>
                            <option value="https://rt4.region1.k12.mn.us/rt0912">Milaca Public Schools</option>
                            <option value="https://psv-milan.tnk12.gov/mil">Milan Special School District</option>
                            <option value="https://ne-mps-psv.edupoint.com">Millard Public Schools</option>
                            <option value="https://ca-mesd-pvue.edupoint.com">Millbrae School District</option>
                            <option value="https://or-mfusd.edupoint.com/">Milton-Freewater Unified School District</option>
                            <option value="https://pvue5.region1.k12.mn.us/rt1100">Minnesota Department of Corrections</option>
                            <option value="https://mn0160vue.tiescloud.net/mn0160">Minnesota State Academies</option>
                            <option value="https://rt2.region1.k12.mn.us/rt2149">Minnewaska Area Schools</option>
                            <option value="https://ga-mcs.edupoint.com/">Mitchell County School District</option>
                            <option value="https://or-msd.edupoint.com">Mitchell School District</option>
                            <option value="https://az-mesd86.edupoint.com/">Mobile Elementary School District #86</option>
                            <option value="https://malc.apscc.org">Mohave Accelerated Schools</option>
                            <option value="https://mvesd.apscc.org">Mohave Valley Elementary School District #16</option>
                            <option value="https://mohawk.apscc.org">Mohawk Valley School District No. 17</option>
                            <option value="https://synergy.clackesd.k12.or.us/MRSD/">Molalla River School District</option>
                            <option value="HTTPS://sis-psvue2.tnk12.gov/MONCS">Monroe County Schools</option>
                            <option value="https://ca-musd.edupoint.com">Montebello Unified School district</option>
                            <option value="https://ca-mpusd-psv.edupoint.com">Monterey Peninsula Unified School District</option>
                            <option value="https://az-mai.edupoint.com/">Montessori Academy Inc</option>
                            <option value="https://md-mcps-psv.edupoint.com">Montgomery County Public Schools</option>
                            <option value="https://studentvue.geneseeisd.org/Mont">Montrose Community Schools</option>
                            <option value="https://or-mosd.edupoint.com/">Monument School District</option>
                            <option value="https://rt2.region1.k12.mn.us/rt0332">Mora Public Schools</option>
                            <option value="https://morenci.apscc.org">Morenci Unified School District #18</option>
                            <option value="https://ca-mor-psv.edupoint.com/">Morongo Unified School District</option>
                            <option value="https://az-mesd.edupoint.com/">Morristown Elementary School District</option>
                            <option value="https://studentvue.geneseeisd.org/GMMC">Mott Middle College</option>
                            <option value="https://MN-MVPS-PSV.edupoint.com">Mounds View Public Schools</option>
                            <option value="https://ca-mtv.edupoint.com/">Mountain View School District</option>
                            <option value="https://studentvue.geneseeisd.org/Mtmr">Mt. Morris Consolidated Schools</option>
                            <option value="https://sdhome.sdcoe.net/Mulberry">Mulberry School</option>
                            <option value="https://student-mesd.cascadetech.org/mesd">Multnomah Education Service District</option>
                            <option value="https://az-msd.edupoint.com">Murphy School District</option>
                            <option value="https://nsd.edupoint.com">National School District</option>
                            <option value="https://az-ncad9.edupoint.com/">Navajo County Accommodation District #9</option>
                            <option value="https://student-neahkahnie.cascadetech.org/neahkahnie">Neah-Kah-Nie School District</option>
                            <option value="https://student-nestuccavalley.cascadetech.org/nestuccavalley">Nestucca Valley School District</option>
                            <option value="https://ca-ncsa.edupoint.com">Nevada City School of the Arts</option>
                            <option value="https://ca-njuk8.edupoint.com">Nevada County Office of Education</option>
                            <option value="https://ca-ncsos.edupoint.com">Nevada County Superintendent of Schools</option>
                            <option value="https://ca-nju.edupoint.com/">Nevada Joint Union High School District</option>
                            <option value="https://rt3.region1.k12.mn.us/rt0308">Nevis Public School</option>
                            <option value="https://ny-nhp-psv.edupoint.com">New Hyde Park - Garden City Park UFSD</option>
                            <option value="https://rt2.region1.k12.mn.us/rt0553">New York Mills ISD #553</option>
                            <option value="https://vue.newarkunified.org/PXP">Newark Unified School District</option>
                            <option value="https://student-newberg.cascadetech.org/newberg">Newberg School District</option>
                            <option value="https://parentvue.nn.k12.va.us">Newport News Public Schools</option>
                            <option value="https://va-nps-psv.edupoint.com">Norfolk Public Schools</option>
                            <option value="https://rt3.region1.k12.mn.us/rt2215">Norman County East Schools</option>
                            <option value="https://portal.nbend.k12.or.us">North Bend School District 13</option>
                            <option value="https://pvue9.region1.k12.mn.us/rt0138">North Branch Area Public Schools</option>
                            <option value="https://synergy.clackesd.k12.or.us/NCSD/">North Clackamas Schools</option>
                            <option value="https://sdhome.sdcoe.net/nctt">North County Trade Tech High School</option>
                            <option value="https://ca-nmcusd.edupoint.com">North Monterey County Unified School District</option>
                            <option value="https://parent-northpowder.cascadetech.org/northpowder/">North Powder School District</option>
                            <option value="https://rt4.region1.k12.mn.us/rt4084">North Shore Community School</option>
                            <option value="https://az-nscsi.edupoint.com/">North Star Charter School Inc</option>
                            <option value="https://ca-nhu.edupoint.com">Northern Humboldt Union High School District</option>
                            <option value="https://wa-nor-psv.edupoint.com">Northshore School District No. 417</option>
                            <option value="https://spportal.westmihosting.com/nvps_pxp">Northview Public Schools</option>
                            <option value="https://student-nwresd.cascadetech.org/nwresd">Northwest Regional Education Service District</option>
                            <option value="https://or-nsd.edupoint.com/">Nyssa School District</option>
                            <option value="https://studentvue.geneseeisd.org/oca">Oakland Academy</option>
                            <option value="HTTPS://sis-psvue1.tnk12.gov/OCS">Obion County Schools</option>
                            <option value="https://mn4030vue.tiescloud.net/mn4030">Odyssey Academy</option>
                            <option value="https://opa.edupoint.com">Odyssey Preparatory Academy</option>
                            <option value="https://mn0333vue.tiescloud.net/mn0333">Ogilvie Public School District</option>
                            <option value="https://parentvue.olatheschools.com">Olathe Public Schools</option>
                            <option value="https://pvue10.region1.k12.mn.us/rt0480">Onamia ISD #480</option>
                            <option value="https://or-ont.edupoint.com/">Ontario School District</option>
                            <option value="https://opendoors.apscc.org/">Open Doors Community School</option>
                            <option value="https://synergy.clackesd.k12.or.us/OCSD/">Oregon City School District</option>
                            <option value="https://OR-ODE.edupoint.com">Oregon Department of Education</option>
                            <option value="https://or-osd.edupoint.com">Oregon School for the Deaf</option>
                            <option value="https://synergy.clackesd.k12.or.us/OTSD/">Oregon Trail School District</option>
                            <option value="https://pvue10.region1.k12.mn.us/rt0278">Orono Public Schools</option>
                            <option value="https://genesis.osbornnet.org/production">Osborn School District #8</option>
                            <option value="https://MN-OAS-psv.edupoint.com">Osseo Area Schools</option>
                            <option value="https://ny-ocsd-psv.edupoint.com">Oswego City School District</option>
                            <option value="https://ca-oxn-psv.edupoint.com">Oxnard Union High School District</option>
                            <option value="https://plc.apscc.org">PLC Charter Schools</option>
                            <option value="https://ca-pgusd.edupoint.com">Pacific Grove Unified School District</option>
                            <option value="https://synergy.pacificasd.org/">Pacifica School District</option>
                            <option value="https://ca-pvusd-psv.edupoint.com">Pajaro Valley Unified School District</option>
                            <option value="https://pvue.psusd.us">Palm Springs Unified School District</option>
                            <option value="https://paloverde.apscc.org">Palo Verde Elementary School District</option>
                            <option value="https://paloma.apscc.org">Paloma Elementary School Disrict</option>
                            <option value="https://palominas.apscc.org">Palominas Elementary School District</option>
                            <option value="https://az-pan.edupoint.com">Pan American Charter School</option>
                            <option value="https://ca-pbvusd-psv.edupoint.com">Panama-Buena Vista Union School District</option>
                            <option value="https://parentvue.paramount.k12.ca.us/">Paramount Unified School District</option>
                            <option value="https://studentvue.geneseeisd.org/psd">Parchment School District</option>
                            <option value="https://rt3.region1.k12.mn.us/rt0309">Park Rapids Area Schools</option>
                            <option value="https://parker.apscc.org">Parker Unified School District</option>
                            <option value="https://student-parkrose.cascadetech.org/parkrose">Parkrose School District</option>
                            <option value="http://synergypvue.micase.org/pps">Paw Paw Public Schools</option>
                            <option value="https://peachsprings.apscc.org">Peach Springs Elementary School District</option>
                            <option value="http://pvue.pesd92.org">Pendergast Elementary School District</option>
                            <option value="https://student-pendleton.cascadetech.org/pendleton/">Pendleton School District #16</option>
                            <option value="https://synergylive.pusd11.net">Peoria Unified School District</option>
                            <option value="https://parentvue.pvsd.org">Perkiomen Valley School District</option>
                            <option value="https://psv-perry.tnk12.gov/per">Perry County Schools</option>
                            <option value="https://az-pac.edupoint.com">Phoenix Advantage Charter School</option>
                            <option value="https://synergy.phxschools.org/">Phoenix Elementary School District #1</option>
                            <option value="https://phoenixmodern.apscc.org">Phoenix Modern Charter</option>
                            <option value="https://studentvue.phoenixunion.org">Phoenix Union High School District</option>
                            <option value="https://rt3.region1.k12.mn.us/rt0484">Pierz School District 484</option>
                            <option value="https://parent-pilotrock.cascadetech.org/pilotrock/">Pilot Rock School District</option>
                            <option value="https://pvue9.region1.k12.mn.us/rt0578">Pine City Public Schools</option>
                            <option value="https://or-hesd.edupoint.com">Pine Creek Elementary School</option>
                            <option value="https://pine.apscc.org">Pine Strawberry School District #12</option>
                            <option value="https://az-pps.edupoint.com">Pioneer Preparatory School</option>
                            <option value="https://pvue7.region1.k12.mn.us/rt2689">Pipestone Area Schools</option>
                            <option value="HTTPS://sis-psvue2.tnk12.gov/PCS">Polk County Schools</option>
                            <option value="https://student-portland.cascadetech.org/portland">Portland Public Schools</option>
                            <option value="https://svue.psdschools.org">Poudre School District</option>
                            <option value="https://sis.powayusd.com">Poway Unified School District</option>
                            <option value="https://or-pcsd.edupoint.com/">Prairie City School District</option>
                            <option value="https://pvcs.apscc.org">Prescott Valley Charter School</option>
                            <option value="https://va-pwcps-psv.edupoint.com">Prince William County Public Schools</option>
                            <option value="https://quartzsite.apscc.org">Quartzsite School District #4</option>
                            <option value="https://qcsvue.apscc.org">Queen Creek Unified School District</option>
                            <option value="https://sdhome.sdcoe.net/RAI">RAI Online Charter School</option>
                            <option value="https://me-bea-psv.edupoint.com">RSU #6 Bonny Eagle</option>
                            <option value="https://me-rsu74.edupoint.com">RSU #74</option>
                            <option value="https://me-wat.edupoint.com/">RSU No. 57 Waterboro</option>
                            <option value="https://wa-rai.edupoint.com">Rainier Prep</option>
                            <option value="https://student-rainier.cascadetech.org/rainier">Rainier School District</option>
                            <option value="https://rt2.region1.k12.mn.us/rtRLCC">Red Lake County Central Schools</option>
                            <option value="https://rt1.region1.k12.mn.us/rt0038">Red Lake Schools</option>
                            <option value="https://pvue10.region1.k12.mn.us/rt0256">Red Wing Public Schools</option>
                            <option value="https://pa-rvsd-psv.edupoint.com">Redbank Valley School District</option>
                            <option value="https://or-hi-psv.edupoint.com">Redmond School District</option>
                            <option value="https://studentvue.geneseeisd.org/rhc">Reformed Heritage Christian School</option>
                            <option value="https://az-rts.edupoint.com">Reid Traditional Schools</option>
                            <option value="https://student-reynolds.cascadetech.org/reynolds">Reynolds School District</option>
                            <option value="https://pvue.rialto.k12.ca.us">Rialto Unified School District</option>
                            <option value="https://pvue8.region1.k12.mn.us/rt0280">Richfield Public Schools</option>
                            <option value="https://ca-rvusd.edupoint.com">Rincon Valley Union School District</option>
                            <option value="https://student-riverdale.cascadetech.org/riverdale">Riverdale School District</option>
                            <option value="https://az-resd2.edupoint.com">Riverside Elementary School District</option>
                            <option value="HTTPS://sis-psvue2.tnk12.gov/RCS">Roane County Schools</option>
                            <option value="https://synergypsv.rcps.info">Roanoke City Public Schools</option>
                            <option value="https://va-roan-psv.edupoint.com/">Roanoke County Public Schools</option>
                            <option value="https://rcsvue.rochester.k12.mi.us/">Rochester Community Schools</option>
                            <option value="https://az-rsd-psv.edupoint.com">Roosevelt School District 66</option>
                            <option value="https://student-roseburg.cascadetech.org/roseburg">Roseburg Public Schools</option>
                            <option value="https://ca-rusd-psv.edupoint.com/">Rosedale Union School District</option>
                            <option value="https://mn0196vue.tiescloud.net/mn0196">Rosemount-Apple Valley-Eagan Public Schools</option>
                            <option value="https://pvue.isd623.org/">Roseville Area Schools</option>
                            <option value="https://rt2.region1.k12.mn.us/rt0850">Rothsay Public Schools</option>
                            <option value="https://pvue8.region1.k12.mn.us/rt2907">Round Lake-Brewster Public Schools</option>
                            <option value="https://pvue5.region1.k12.mn.us/rt6079">Rum River Special Education Cooperative</option>
                            <option value="https://pvue9.region1.k12.mn.us/rt0139">Rush City Public Schools</option>
                            <option value="https://mn4087vue.tiescloud.net/mn4087">SAGE Academy</option>
                            <option value="https://psvue.secep.net">SECEP Southeastern Cooperative Educational Programs</option>
                            <option value="https://az-pddpi.edupoint.com/">STAR School</option>
                            <option value="https://pvue10.region1.k12.mn.us/rt4200">STEP Academy</option>
                            <option value="https://az-sesd18.edupoint.com/">Sacaton Elementary School District #18</option>
                            <option value="https://sis-portal.salkeiz.k12.or.us">Salem-Keizer Public Schools</option>
                            <option value="https://ca-suhsd-psv.edupoint.com">Salinas Union High School District</option>
                            <option value="https://az-sfed.edupoint.com/">San Fernando Elementary District</option>
                            <option value="https://portal.sfusd.edu/">San Francisco Unified School District</option>
                            <option value="https://ca-sanmar-psv.edupoint.com">San Marcos Unified School District</option>
                            <option value="https://sdhome.sdcoe.net/SanPasqualEl">San Pasqual Union School District</option>
                            <option value="https://sdhome.sdcoe.net/SanPasqualValley">San Pasqual Valley Unified School District</option>
                            <option value="https://ca-sysd.edupoint.com">San Ysidro School District</option>
                            <option value="https://sanders.apscc.org">Sanders Unified School District</option>
                            <option value="https://az-scvusd.edupoint.com">Santa Cruz Valley Unified School District #35</option>
                            <option value="https://student-scappoose.cascadetech.org/scappoose">Scappoose School District</option>
                            <option value="https://pa-lan-psv.edupoint.com">School District of Lancaster</option>
                            <option value="https://studentvue.geneseeisd.org/scs">Schoolcraft Community Schools</option>
                            <option value="https://ca-svusd.edupoint.com">Scotts Valley Unified School District</option>
                            <option value="https://az-scds.edupoint.com/">Scottsdale Country Day School</option>
                            <option value="https://synergyvue.susd.org">Scottsdale Unified School District</option>
                            <option value="https://student-seaside.cascadetech.org/seaside">Seaside School District</option>
                            <option value="https://AZ-SC.Edupoint.com">Sedona Charter School</option>
                            <option value="https://sdhome.sdcoe.net/Seeley">Seeley Union School District</option>
                            <option value="https://pvue8.region1.k12.mn.us/rt4215">Sejong Academy</option>
                            <option value="https://seligman.apscc.org">Seligman Unified School District #40</option>
                            <option value="https://sentinel.apscc.org/">Sentinel Elementary District No. 71</option>
                            <option value="https://or-ser.edupoint.com/">Serendipity Center</option>
                            <option value="https://pa-sha-psv.edupoint.com">Shamokin Area School District</option>
                            <option value="https://pa-scsd-psv.edupoint.com">Sharon City School District</option>
                            <option value="https://or-scsd.edupoint.com">Sherman County School District</option>
                            <option value="https://student-sherwood.cascadetech.org/sherwood">Sherwood School District</option>
                            <option value="https://shontoprep.apscc.org">Shonto Preparatory Academy</option>
                            <option value="https://svvue.apscc.org/">Sierra Vista Unified School District</option>
                            <option value="https://slsd.edupoint.com">Siuslaw School District 97J</option>
                            <option value="https://az-ssi.edupoint.com/">Skyline Schools Inc</option>
                            <option value="https://az-sky.edupoint.com">Skyview School</option>
                            <option value="https://pvue8.region1.k12.mn.us/rt4038">Sojourner Truth Academy</option>
                            <option value="https://az-sed.edupoint.com/">Solomon Elementary District</option>
                            <option value="https://sonoita.apscc.org/">Sonoita Elementary School District</option>
                            <option value="https://az-sds.edupoint.com">Sonoran Desert School</option>
                            <option value="https://sdhome.sdcoe.net/SouthBay">South Bay Union School District</option>
                            <option value="https://ne-ssccs.edupoint.com">South Sioux City Community Schools</option>
                            <option value="https://pvue10.region1.k12.mn.us/rt0006">South St. Paul Public Schools</option>
                            <option value="https://pvue5.region1.k12.mn.us/rt0288">SouthWest Metro Educational Cooperative</option>
                            <option value="https://pvue8.region1.k12.mn.us/rt4162">Southside Family Charter School</option>
                            <option value="https://student-odecharter.cascadetech.org/odecharter">Southwest Charter School</option>
                            <option value="https://sdhome.sdcoe.net/SpencerValley">Spencer Valley Elementary School District</option>
                            <option value="https://pvue8.region1.k12.mn.us/rt4113">Spero Academy</option>
                            <option value="https://parent.spotsylvania.k12.va.us/">Spotsylvania County Schools</option>
                            <option value="https://or-spray.edupoint.com">Spray School District</option>
                            <option value="https://nm-sms.edupoint.com/">Springer Municipal Schools</option>
                            <option value="https://sv.sps.sis.k12.or.us">Springfield Public Schools</option>
                            <option value="https://pvue7.region1.k12.mn.us/rt4120">St. Croix Preparatory Academy</option>
                            <option value="https://stdavid.apscc.org">St. David Unified School District #21</option>
                            <option value="https://student-sthelens.cascadetech.org/sthelens">St. Helens School District</option>
                            <option value="https://pvue7.region1.k12.mn.us/rt0885">St. Michael-Albertville Schools</option>
                            <option value="https://pvue8.region1.k12.mn.us/rt4029">St. Paul City School</option>
                            <option value="https://mn4112vue.tiescloud.net/mn4112">St. Paul Conservatory for Performing Arts</option>
                            <option value="https://psp.staffordschools.net">Stafford County Public Schools</option>
                            <option value="https://pa-scasd-psv.edupoint.com">State College Area School District</option>
                            <option value="https://sis.stocktonusd.net">Stockton Unified School District</option>
                            <option value="https://or-hesd.edupoint.com">Suntex Elementary School</option>
                            <option value="https://student-sutherlin.cascadetech.org/sutherlin">Sutherlin School District</option>
                            <option value="HTTPS://sis-psvue2.tnk12.gov/SCS">Sweetwater City Schools</option>
                            <option value="https://az-sysi.edupoint.com/">SySTEM Schools Inc</option>
                            <option value="https://vue1.tiescloud.net/mn0006">TIES - Technology and Information Educational Services</option>
                            <option value="https://pvue9.region1.k12.mn.us/rt4095">TRIO Wolf Creek Distance Learning Charter School</option>
                            <option value="https://ca-tamal-psv.edupoint.com/">Tamalpais Union High School District</option>
                            <option value="https://pxp.tanq.org/">Tanque Verde Unified School District</option>
                            <option value="https://synergy.telesis-academy.org/">Telesis Preparatory Academy</option>
                            <option value="https://parentvue.tempeschools.org">Tempe Elementary School District</option>
                            <option value="https://az-tuhsd-psv.edupoint.com">Tempe Union High School District</option>
                            <option value="https://vue.thatcherud.org">Thatcher Unified School District</option>
                            <option value="https://MD-TCG.Edupoint.com">The Children's Guild</option>
                            <option value="https://sdhome.sdcoe.net/HeightsCharter">The Heights Charter School</option>
                            <option value="https://student-odecharter.cascadetech.org/odecharter">The Ivy School</option>
                            <option value="https://leonagroup.apscc.org/">The Leona Group</option>
                            <option value="https://az-tps.edupoint.com/">The PEAK School</option>
                            <option value="https://rt2.region1.k12.mn.us/rt0564">Thief River Falls Public Schools</option>
                            <option value="https://3rivers.sis.k12.or.us/">Three Rivers School District</option>
                            <option value="https://student-tigardtualatin.cascadetech.org/tigardtualatin">Tigard-Tualatin School District</option>
                            <option value="https://student-tillamook.cascadetech.org/tillamook">Tillamook School District</option>
                            <option value="https://tolleson.apscc.org">Tolleson Elementary School District #17</option>
                            <option value="https://pxp.tuhsd.org">Tolleson Union High School District</option>
                            <option value="https://tombstone.apscc.org">Tombstone Unified School District #1</option>
                            <option value="HTTPS://sis-psvue1.tnk12.gov/TSSD">Trenton Special School District</option>
                            <option value="https://spportal.westmihosting.com/TCAS_PXP">Tri-County Area Schools</option>
                            <option value="https://rt3.region1.k12.mn.us/rt2358">Tri-County Public School</option>
                            <option value="https://tcds.apscc.org">Tucson Country Day School</option>
                            <option value="https://student.tusd1.org">Tucson Unified School District</option>
                            <option value="https://pvue10.region1.k12.mn.us/rt4078">Twin Cities International Schools</option>
                            <option value="https://parent-ukiah.cascadetech.org/ukiah/">Ukiah School District 80R</option>
                            <option value="https://rt1.region1.k12.mn.us/rt0550">Underwood School District</option>
                            <option value="https://uesd.apscc.org">Union Elementary School District</option>
                            <option value="https://parent-union.cascadetech.org/union/">Union School District</option>
                            <option value="https://il-upccsd.edupoint.com">Unity Point Community Consolidated School District 140</option>
                            <option value="https://or-val.edupoint.com/">Vale School District</option>
                            <option value="https://sdhome.sdcoe.net/Vallecitos">Vallecitos District Office</option>
                            <option value="HTTPS://sis-psvue1.tnk12.gov/VBCS">Van Buren County Schools</option>
                            <option value="https://student-vernonia.cascadetech.org/vernonia">Vernonia School District</option>
                            <option value="https://ca-vesd.edupoint.com">Victor Elementary School District</option>
                            <option value="https://studentvue.vbcps.com">Virginia Beach City Public Schools</option>
                            <option value="https://vista.apscc.org">Vista College Prep</option>
                            <option value="https://or-wal.edupoint.com">Wallowa School District</option>
                            <option value="https://in-wts-psv.edupoint.com">Warren Township, Metropolitan School District of</option>
                            <option value="https://student-warrentonhammond.cascadetech.org/warrentonhammond">Warrenton-Hammond School District</option>
                            <option value="https://synergy.warrick.k12.in.us/production">Warrick County School Corporation</option>
                            <option value="https://rt3.region1.k12.mn.us/rt0690">Warroad Public Schools</option>
                            <option value="https://spportal.wcps.k12.md.us">Washington County Public Schools</option>
                            <option value="https://parentvue.wesdschools.org">Washington Elementary School District No. 6</option>
                            <option value="https://pvue5.region1.k12.mn.us/rt4092">Watershed High School</option>
                            <option value="https://pvue10.region1.k12.mn.us/rt0435">Waubun-Ogema-White Earth Schools #435</option>
                            <option value="https://psv-wayne.tnk12.gov/way">Wayne County Schools</option>
                            <option value="HTTPS://sis-psvue1.tnk12.gov/WCS">Weakley County Schools</option>
                            <option value="https://psv-westcarroll.tnk12.gov/wca">West Carroll Special School District</option>
                            <option value="https://rt4.region1.k12.mn.us/rt2342">West Central Area Schools</option>
                            <option value="https://ny-wgen-psv.edupoint.com">West Genesee School District</option>
                            <option value="https://synergy-parstuvue.wlwv.k12.or.us">West Linn - Wilsonville School District</option>
                            <option value="https://wsst.apscc.org/">Western School of Science and Technology</option>
                            <option value="https://studentvue.geneseeisd.org/West">Westwood Heights School District</option>
                            <option value="https://mn-wbl-psv.edupoint.com">White Bear Lake Area Schools</option>
                            <option value="https://studentvue.geneseeisd.org/wpc">White Pigeon Community Schools</option>
                            <option value="https://ks-wic-psv.edupoint.com/">Wichita Public Schools</option>
                            <option value="https://az-wusd9.edupoint.com/">Wickenburg Unified School District #9</option>
                            <option value="https://va-wjccp-psv.edupoint.com/">Williamsburg James City County Public Schools</option>
                            <option value="https://rt1.region1.k12.mn.us/rt2609">Win-E-Mac School</option>
                            <option value="https://or-wdsd-psv.edupoint.com">Winston-Dillard School District</option>
                            <option value="https://pvue5.region1.k12.mn.us/rt4228">Woodbury Leadership Academy</option>
                            <option value="https://studentvue.geneseeisd.org/wpa">Woodland Park Academy</option>
                            <option value="https://spportal.westmihosting.com/wps_pxp">Wyoming Public Schools</option>
                            <option value="https://az-ypic.edupoint.com/">YPIC - Yuma Private Industry Council</option>
                            <option value="https://student-yamhillcarlton.cascadetech.org/yamhillcarlton">Yamhill Carlton School District</option>
                            <option value="https://ysd.edupoint.com/">Yoncalla School District</option>
                            <option value="https://young.apscc.org">Young Public School</option>
                            <option value="https://ysacs.edupoint.com">Young Scholar's Academy</option>
                            <option value="https://studentvue.geneseeisd.org/yaa">Youth Advancement Academy</option>
                            <option value="https://mn0926.tiescloud.net/mn0926">Youth Educational Services</option>
                            <option value="https://az-yesd-psv.edupoint.com/">Yuma School District #1</option>
                            <option value="https://az-yuhsd-psv.edupoint.com/">Yuma Union High School District</option>
                        </select>
                        </div>
                        </div>
                        <div className="w-full">

                        <div className="mx-auto text-center mt-4 ">
                        <button onClick={submitForm} className="btn btn-accent text-center w-full">
                            <p className="font-1 text-base">Login</p>
                        </button>
                        </div>
                        
                        </div>
                        
                    </div>
                    
                    

                </div>


            </div>
            

            
        </div>
        <div className="sm:block hidden w-full bg-primary">
                <div className="w-4/6 mx-auto text-white  relative">
            
                    <Footer />
                </div>
            </div>

        </>
    )
}


export default Login;