import React, {useState, useEffect} from "react";
import Navbar from "./Navbar"
import Card from "./Card"
import Footer from "./Footer"


// let gradeBand = [89.5, 79.5, 69.5, 59.5, 49.5]

// let assignmentTypes = 
// [
//     // {
//     //     assignmentType: "Practice Prep",
//     //     categoryWorth: 10
//     // },
//     // {
//     //     assignmentType: "All Task",
//     //     categoryWorth: 90
//     // }
// ]

// let assignments = [
//     // {
//     // assignmentName: "Chapter 16 reading guide",
//     // assignmentType: "Practice Prep",
//     // assignmentCategoryWorth: 10,
//     // totalPoints: 15,
//     // totalEarned: 10,
//     // date: "8/12/2024"
//     // },
//     // {
//     //     assignmentName: "AT Semester 2 exam",
//     //     assignmentType: "All Task",
//     //     assignmentCategoryWorth: 90,
//     //     totalPoints: 25,
//     //     totalEarned: 25,
//     //     date: "8/12/2024"
//     //     },
//     // {
//     //     assignmentName: "Chapter 17 quiz FA",
//     //     assignmentType: "All Task",
//     //     assignmentCategoryWorth: 90,
//     //     totalPoints: 15,
//     //     totalEarned: 10,
//     //     date: "12/12/2024"
//     // },
//     // {
//     //     assignmentName: "Semester 1 exam",
//     //     assignmentType: "All Task",
//     //     assignmentCategoryWorth: 90,
//     //     totalPoints: 15,
//     //     totalEarned: 10,
//     //     date: "8/2/2024"
//     // },
//     // {
//     //     assignmentName: "Semester 1 reading guide",
//     //     assignmentType: "Practice Prep",
//     //     assignmentCategoryWorth: 10,
//     //     totalPoints: 15,
//     //     totalEarned: 10,
//     //     date: "8/12/2018"
//     // }
// ]



// let courses = [
//     // {
//     //     courseName: "AP Macroeconomics",
//     //     courseTeacher: "Schilicter",
//     //     grade: 92.49,
//     //     period: 1,
//     // },
//     // {
//     //     courseName: "AP Macro",
//     //     courseTeacher: "Schilicter",
//     //     grade: 87.45,
//     //     period: 2

//     // },
//     // {
//     //     courseName: "AP Macro",
//     //     courseTeacher: "Schilicter",
//     //     grade: 75.49,
//     //     period: 3

//     // },
//     // {
//     //     courseName: "AP Macro",
//     //     courseTeacher: "Schilicter",
//     //     grade: 52.12,
//     //     period: 4

//     // },{
//     //     courseName: "AP Macro",
//     //     courseTeacher: "Schilicter",
//     //     grade: 76.49,
//     //     period: 5

//     // },
//     // {
//     //     courseName: "AP Macro",
//     //     courseTeacher: "Schilicter",
//     //     grade: 87.45,
//     //     period: 6

//     // },
//     // {
//     //     courseName: "AP Macro",
//     //     courseTeacher: "Schilicter",
//     //     grade: 62.59,
//     //     period: 7

//     // },
// ]
const listOfQuotes = ["The only way to do great work is to love what you do. - Steve Jobs",
"Success is not final, failure is not fatal: It is the courage to continue that counts. - Winston Churchill",
"Innovation distinguishes between a leader and a follower. - Steve Jobs",
"Believe you can and you're halfway there. - Theodore Roosevelt",
"The future belongs to those who believe in the beauty of their dreams. - Eleanor Roosevelt",
"Strive not to be a success, but rather to be of value. - Albert Einstein",
"The only thing standing between you and your goal is the story you keep telling yourself as to why you can't achieve it. - Jordan Belfort",
"You miss 100% of the shots you don't take. - Wayne Gretzky",
"The way to get started is to quit talking and begin doing. - Walt Disney",
"Your time is limited, don't waste it living someone else's life. - Steve Jobs",
"Success is walking from failure to failure with no loss of enthusiasm. - Winston Churchill",
"It does not matter how slowly you go as long as you do not stop. - Confucius",
"Everything you can imagine is real. - Pablo Picasso",
"What you get by achieving your goals is not as important as what you become by achieving your goals. - Zig Ziglar",
"You are never too old to set another goal or to dream a new dream. - C.S. Lewis",
"Don't watch the clock; do what it does. Keep going. - Sam Levenson",
"Our greatest glory is not in never falling, but in rising every time we fall. - Confucius",
"The only limit to our realization of tomorrow will be our doubts of today. - Franklin D. Roosevelt",
"The best time to plant a tree was 20 years ago. The second best time is now. - Chinese Proverb",
"If you can dream it, you can do it. - Walt Disney",
"The secret of getting ahead is getting started. - Mark Twain",
"It's not whether you get knocked down, it's whether you get up. - Vince Lombardi",
"People who are crazy enough to think they can change the world, are the ones who do. - Rob Siltanen",
"The only place where success comes before work is in the dictionary. - Vidal Sassoon",
"Whatever you are, be a good one. - Abraham Lincoln",
"You can't use up creativity. The more you use, the more you have. - Maya Angelou",
"Nothing is impossible, the word itself says 'I'm possible'! - Audrey Hepburn",
"The best revenge is massive success. - Frank Sinatra",
"Opportunities don't happen, you create them. - Chris Grosser",
"You miss 100% of the shots you don't take. - Wayne Gretzky",
"What you get by achieving your goals is not as important as what you become by achieving your goals. - Zig Ziglar",
"Believe you can and you're halfway there. - Theodore Roosevelt",
"The future belongs to those who believe in the beauty of their dreams. - Eleanor Roosevelt",
"Strive not to be a success, but rather to be of value. - Albert Einstein",
"The only way to do great work is to love what you do. - Steve Jobs",
"Success is not final, failure is not fatal: It is the courage to continue that counts. - Winston Churchill",
"Innovation distinguishes between a leader and a follower. - Steve Jobs",
"The only thing standing between you and your goal is the story you keep telling yourself as to why you can't achieve it. - Jordan Belfort"
]

const url = "https://api.gradevue.com/doeverything"
function Dashboard(props) {
    let thing;
    const [gpa, setGpa] = useState(4)
    const [courses, setCourses] = useState([])
    const [weightedClasses, setWeightedClasses] = useState(Array(courses.length).fill(false))
    const [currentCard, setCurrentCard] = useState()
    const [firstName, setFirstName] = useState("")
    const [assignments, setAssignments] = useState([])
    const [assignmentTypes, setAssignmentTypes] = useState([])
    const [loading, setLoading] = useState(true)
    const [gradeBand, setGradeBand] = useState([])


    const callApi = async (endpoint) => {

        return props.assignmentData
        // console.log(endpoint)
        // const body = {
        //     endpoint: endpoint
        // }
        // const response = await fetch(url, {
        //     method: "POST",
        //     mode: "cors",
        //     headers: {
        //         "Content-Type": "application/json"
        //     },
        //     credentials: "include",
            
        //     body: JSON.stringify(body)
        // })

        // return response.json()
    }



    useEffect(() => {
        console.log("this happened right before the props.endpoint", props.endpoint)
        if (props.endpoint != "") {
            console.log("called the api")

            callApi(props.endpoint).then((response) => {
                console.log(response)
                if (response.message === "invalid") {
                    console.log("invalid")
                    props.setCurrentScreen("Login")
                } else if (response.message === "no user found") {
                    props.setCurrentScreen("Login")
                } else if (response.code == "ok") {
                    console.log("this response code triggered")

                    let gradeBand = []
                    console.log(response)
                    response.message.gradeBands.map((mark) => {
                        gradeBand.push({lowScore: mark.lowScore, mark: mark.mark})
                    })
                    console.log("heres the gradeBand", gradeBand)
                    setGradeBand(gradeBand)
                    let localAssignments = []

                    let typeMap = new Map();

                    response.message.assignmentTypes.map((type) => {
                        typeMap.set(type.assignmentType, type.categoryWorth)
                    })

                    
                    response.message.allAssignments.map((assignmentList) => {
                        let currentList = []
                        let keys = Object.keys(assignmentList)
                        keys.map((key) => {
                            if (assignmentList[key].totalEarned != (null||undefined)) {
                                currentList.push({...assignmentList[key], categoryWorth: typeMap.get(assignmentList[key].category)})
                            }
                            

                            // assignments = assignmentList.key
                        })
                        response.message.assignmentTypes.map((assignment) => {
                            currentList.push(
                                {
                                    category: assignment.assignmentType,
                                    totalEarned: 0,
                                    totalPoints: 0,
                                    name: "fadledme",
                                    date: 0
    
    
                                }
                            )

                        })
                        

                        localAssignments.push(currentList)
                        
                        
                        
                    })
                    console.log("heres the localAssignments", localAssignments)

                    setAssignments(localAssignments)
                    console.log("heres the courses", response.message.initialGrades)
                    setCourses(response.message.initialGrades)

                    let localCourses = response.message.initialGrades
                    let localWeighted = Array(courses.length).fill(false)
                    localCourses.map((course, i) => {
                        if (course.courseName.substring(0,2).toLowerCase() === ("ap")) {
                            localWeighted[i] = true
                        } else if (course.courseName.substring(0,3).toLowerCase() === ("hon")) {
                            localWeighted[i] = true
                        }
                    })
                    localWeighted[0] = true
                    setWeightedClasses(localWeighted)




                    console.log("heres the gradeBand", response.message.assignmentTypes)

                    // sessionStorage.setItem()







                    setFirstName(response.message.name)
                    setAssignmentTypes(response.message.assignmentTypes)
                    setLoading(false)
                } else {
                    props.setCurrentScreen("Login")
                    console.log("some unknown error")
                }

                
            })



            

            



        }
    }, [])
        
























    const changeWeight = (event) => {
        console.log("this is happening")
        setWeightedClasses(prevClasses => {
            const classes = [...prevClasses]
            classes[event.target.id] = event.target.checked
            return classes
        })
    }


    useEffect(() => {
        
        
        for (let i=0; i<courses.length; i++) {


            

            for (let k=0; k < gradeBand.length; k++) {
                if (courses[i].grade >= gradeBand[k].lowScore) {
                    setCourses(prevCourses => {
                        let thing = [...prevCourses];
                        thing[i].letter = gradeBand[k].mark;
                        return thing;
                    });
                    break;
                }
            }
            // Refactor code to loop through gradeBands.
            // if (courses[i].grade >= gradeBand[0]) {
            //     setCourses(prevCourses => {
            //         let thing = [...prevCourses]
            //         thing[i].letter = gradeBand[k].mark
            //         return thing
            //     })
                
            // } else if (courses[i].grade >= gradeBand[1]) {
            //     setCourses(prevCourses => {
            //         let thing = [...prevCourses]
            //         thing[i].letter = "B"
            //         return thing
            //     })
            // } else if (courses[i].grade >= gradeBand[2]) {
            //     setCourses(prevCourses => {
            //         let thing = [...prevCourses]
            //         thing[i].letter = "C"
            //         return thing
            //     })
            // }else if (courses[i].grade >= gradeBand[3]) {
            //     setCourses(prevCourses => {
            //         let thing = [...prevCourses]
            //         thing[i].letter = "D"
            //         return thing
            //     })
            // } else if (courses[i].grade >= gradeBand[4]) {
            //     setCourses(prevCourses => {
            //         let thing = [...prevCourses]
            //         thing[i].letter = "E"
            //         return thing
            //     })
            // }


            
            // else if (courses[i].grade >= )
        }
    },[weightedClasses])


    // function displayGrades(course) {

    //     const colors = ["#22c55e","#3b82f6",
    //         "#eab308","#f97316","#ef4444"
    //     ]
    //     const lastColor = "#dc2626"
        
    //     gradeBand.map((band, i) => {
    //         if (course.grade >= band.lowScore) {
                
    //             if (colors.length >= i) {
    //                 return (
    //                     <div style={{color: colors[i]}}>
    //                         <p className="font-extrabold font-1 text-left text-2xl">{band.mark}</p>
    //                         <p className="font-1 text-left font-extrabold text-lg">{course.grade}%</p>
                  
    //                     </div>
    //                 )
    //             } else {

    //                 return (
    //                     <div style={{color: lastColor}}>
    //                         <p className="font-extrabold font-1 text-left text-2xl">{band.mark}</p>
    //                         <p className="font-1 text-left font-extrabold text-lg">{course.grade}%</p>
                  
    //                     </div>
    //                 )
                    
    //             }


                
    //         }
            
            
    //     })




    // }
    function displayGrades(course) {
        const colors = ["#22c55e", "#3b82f6", "#eab308", "#f97316", "#ef4444"];
        const lastColor = "#dc2626";
    
        for (let i = 0; i < gradeBand.length; i++) {
            const band = gradeBand[i];
            if (course.grade >= band.lowScore) {
                const color = i < colors.length ? colors[i] : lastColor;
                return (
                    <div style={{ color }}>
                        <p className="font-extrabold font-1 text-left text-2xl">{band.mark}</p>
                        <p className="font-1 text-left font-extrabold text-lg">{course.grade}%</p>
                    </div>
                );
            }
        }
    
        // If no grade band matches, return default JSX or handle it accordingly
        return null;
    }
    




    useEffect(() => {
        let gradeNum = 0
        console.log("recalculating grade")
        for (let i=0; i<courses.length; i++) {
            switch (courses[i].letter) {
                case "A": 
                    gradeNum += 4
                    break;  
                case "B": 
                    gradeNum += 3
                    break;  
                case "C": 
                    gradeNum += 2
                    break;  
                case "D": 
                    gradeNum += 1
                    break;  
                case "E": 
                    gradeNum += 0
                    break;  
            }
            
            
        }
        for (let k=0; k<weightedClasses.length; k++) {
            if (weightedClasses[k] == true) {
                gradeNum += 1
            } 
        }
 
        gradeNum = gradeNum * 4
        const finalGPA = parseFloat(gradeNum/(courses.length*4)).toFixed(2)
        
        setGpa(finalGPA) 
    }, [courses])


  






    

    




    return (
        <>



        {(loading) && (
            <>
            <div className="w-screen h-screen absolute bg-white opacity-80 z-40"></div>
            <div className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-40">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-40 h-40 animate-spin">
                <path fillRule="evenodd" d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 0 0-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 0 0-2.282.819l-.922 1.597a1.875 1.875 0 0 0 .432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 0 0 0 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 0 0-.432 2.385l.922 1.597a1.875 1.875 0 0 0 2.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 0 0 2.28-.819l.923-1.597a1.875 1.875 0 0 0-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 0 0 0-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 0 0-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 0 0-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 0 0-1.85-1.567h-1.843ZM12 15.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z" clipRule="evenodd" />
            </svg>
            


            </div>
            <div className="fixed left-[50%] translate-x-[-50%] bottom-[10%] z-40">
            
            <p className="italic font-2 text-center text-lg">"{listOfQuotes[Math.floor(Math.random()*(listOfQuotes.length-1))]}"</p>
            <p className="font-1 text-center text-lg text-black animate-bounce mt-8">This will take up to 8 seconds</p>
            </div>
            


            </>
        )}





        {(loading === false) && (
            <>
            {(currentCard != (undefined)) && (
            <Card name={firstName} course={courses[currentCard]} gradeBand={gradeBand}  assignmentTypes={assignmentTypes} assignments={assignments[currentCard]} setCurrentCard={setCurrentCard} />
        )}
        {(currentCard == (undefined)) && (
            <section className="bg-primary min-h-screen">

            <div className="w-full mx-auto">
                <div className="">
                    <Navbar setCurrentScreen={props.setCurrentScreen} name={firstName} />
                </div>
                

                

                
            </div>
            <div className="sm:w-4/6 w-full mx-auto mt-[2%] bg-secondary card sm:p-5 pt-2">
                <div className="">
                <div className="sm:hidden block ">
                    <p className="text-center font-1 text-lg font-semibold pt-2 text-base-100">Hello, <br />{firstName}!</p>
                </div>
                <div className="mt-2 w-fit mx-auto px-5 py-3 mb-10 rounded-lg bg-primary text-white1 ">
                    <p className="text-center font-1 font-semibold text-lg card-title">Cummulative WGPA:</p>
                    <p className="text-center font-1 font-extrabold text-2xl">{gpa}</p> 
                    
                    
                </div>
                
                
                </div>
                
            
            <div className="sm:flex grid grid-cols-1 sm:flex-row sm:flex-wrap gap-8 p-4 bg-primary shadow-md rounded-lg sm:p-10">
                
                {(courses != (null||undefined)) && (
                    courses.map((course, __) => (
                    <div className="shadow-sm p-5 rounded-b-lg relative bg-secondary text-base-100" key={__}>

                    {((__%3 == 0) && (__ !== 0)) && (
                        <>
                        <amp-ad width="100vw" height="320"
     type="adsense"
     data-ad-client="ca-pub-7914515000030137"
     data-ad-slot="7201873916"
     data-auto-format="rspv"
     data-full-width="">
  <div overflow=""></div>
</amp-ad>
</>
                    )}
                    <div className="grid grid-cols-3 items-center gap-x-6 pt-5">
                        <div className="">

                        {displayGrades(course)}
                        {/* {(course.grade >= gradeBand[0]) && (
                            <>
                            <p className="font-extrabold text-green-500 font-1 text-left text-2xl">A</p>
                            <p className="font-1 text-left text-green-500 font-extrabold text-lg">{course.grade}%</p>
                            </>
                        )}
                        {((course.grade >= gradeBand[1]) && (course.grade < gradeBand[0])) && (
                            <>
                            <p className="font-extrabold text-blue-500 font-1 text-left text-2xl">B</p>
                            <p className="font-1 text-left text-blue-500 font-extrabold text-lg">{course.grade}%</p>
                            </>
                        )}
                        {((course.grade >= gradeBand[2]) && (course.grade < gradeBand[1])) && (
                            <>
                            <p className="font-extrabold text-yellow-500 font-1 text-left text-2xl">C</p>
                            <p className="font-1 text-left text-yellow-500 font-extrabold text-lg">{course.grade}%</p>
                            </>
                        )}
                        {((course.grade >= gradeBand[3]) && (course.grade < gradeBand[2])) && (
                            <>
                            <p className="font-extrabold text-orange-500 font-1 text-left text-2xl">D</p>
                            <p className="font-1 text-left text-orange-500 font-extrabold text-lg">{course.grade}%</p>
                            </>
                        )}
                        {((course.grade >= gradeBand[4]) && (course.grade < gradeBand[3])) && (
                            <>
                            <p className="font-extrabold text-red-500 font-1 text-left text-2xl">E</p>
                            <p className="font-1 text-left text-red-500 font-extrabold text-lg">{course.grade}%</p>
                            </>
                        )} */}
                        
                        </div>

                        <div className="col-span-2">
                                <p className="font-bold font-1 text-lg">{course.period} - {course.courseName}</p>
                                <p className="font-1 text-base">{course.courseTeacher}</p>
                                <p id={__} onClick={(event) => setCurrentCard(event.target.id)} className="px-5 py-2 bg-red-500 w-fit rounded-lg text-white font-1 font-bold select-none cursor-pointer hover:bg-red-600">View</p>
                        </div>
                    </div>  
                    <div className="h-8 absolute w-full bg-neutral left-0 top-0 ">
                    <p className="text-left ml-2 font-1 text-base mt-1 inline-block">Weighted?</p>
                    {console.log("i value is " + __ + "and the weightedClasse value is", weightedClasses[__])}
                    <input id={__} onChange={(event) => changeWeight(event)} type="checkbox" checked={weightedClasses[__]} className="inline-block ml-3 scale-125 pt-1" />

                    </div>

                    
                    

                </div>
                ))
                )}
                
                
                
            </div>
            </div>

            <div>

            </div>
                <div className="bg-primary mt-14 text-white sm:block hidden">
                <div className="mx-auto w-4/6 ">
                <Footer />
                </div>
                </div>
        </section>
        
        )}
        
            </>


        )}
        
        
        



        
        





        </>
    )
}


export default Dashboard